import { Directive, ElementRef, input, OnInit } from '@angular/core';
import { SwsButtonShape, SwsButtonSize, SwsButtonType } from '@model';

@Directive({
  selector: '[sws-button]',
  standalone: false,
})
export class SwsButtonDirective implements OnInit {
  swsType = input<SwsButtonType>('primary');
  swsBlock = input<boolean>(false);
  swsIconOnly = input<boolean>(false);
  swsBordered = input<boolean>(true);
  swsSize = input<SwsButtonSize>('md');
  swsShape = input<SwsButtonShape>('round');

  constructor(private elementRef: ElementRef<HTMLButtonElement>) {
    this.elementRef.nativeElement.classList.add('sws-btn');
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add(`sws-btn-${this.swsType()}`);
    this.elementRef.nativeElement.classList.add(`sws-btn-${this.swsSize()}`);
    this.elementRef.nativeElement.classList.add(`sws-btn-${this.swsShape()}`);

    const block = this.swsBlock();
    if (block) {
      this.elementRef.nativeElement.classList.add('sws-btn-block');
    }

    const iconOnly = this.swsIconOnly();
    if (iconOnly) {
      this.elementRef.nativeElement.classList.add('sws-btn-icon');

      // if (this.swsShape() == 'circle') {
      //   this.elementRef.nativeElement.classList.remove('sws-btn-icon');
      //   this.elementRef.nativeElement.classList.remove('sws-btn-icon-only');
      // }
    }

    const bordered = this.swsBordered();
    if (!bordered) {
      this.elementRef.nativeElement.classList.add(`sws-btn-borderless`);
    }
  }
}
