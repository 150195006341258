import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { CommonService } from '@services';
import * as CityActions from './city.action';
import * as AuthActions from '../auth';

@Injectable()
export class CityEffects {
  constructor(
    private actions$: Actions,
    private commonService: CommonService,
    private router: Router
  ) {}

  onLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => CityActions.loadAllCities())
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActions.loadAllCities),
      mergeMap(() =>
        this.commonService.loadAllCities().pipe(
          map(cityGroups => CityActions.loadAllCitiesSuccess({ data: [...cityGroups] })),
          catchError(() => of(CityActions.loadAllCitiesFailed()))
        )
      )
    )
  );
}
