import { Attribute, Component, inject, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { FormArray, FormGroupDirective } from '@angular/forms';
import { ISupporterInfo, supporterTableColumns, SwsColumnType } from '@model';
import { SWS_MODAL_FORM } from '@util';
import { SupporterFormComponent } from '@components';
import { Store } from '@ngrx/store';
import { getSelectedApplicationFormStatus, IAppState } from '@store';

@Component({
  selector: 'sws-supporter-info',
  templateUrl: './supporter.component.html',
  styleUrl: './supporter.component.scss',
  standalone: false,
})
export class SupporterInfoComponent implements OnInit {
  form!: FormArray;
  formStatus$ = inject(Store<IAppState>).select(getSelectedApplicationFormStatus);
  @ViewChild('actionRef', { static: true })
  actionRef!: TemplateRef<ISupporterInfo>;

  columns!: Array<SwsColumnType<ISupporterInfo>>;
  useModalForm = inject(SWS_MODAL_FORM);

  constructor(
    @Attribute('formArrayName') readonly formArrayName: string,
    private readonly rootForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.form = this.rootForm.control.get(this.formArrayName) as FormArray;
    this.columns = supporterTableColumns(this.actionRef);
  }

  get supporters(): ISupporterInfo[] {
    return this.form?.value || [];
  }

  addNewSupporter = () => this.useModalForm('Supporter Information', SupporterFormComponent, this.form);

  remove(index: number) {
    this.form.removeAt(index);
  }
}
