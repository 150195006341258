export const formatCsc = (csc: string | string[]) => {
  if (Array.isArray(csc) && csc.length > 0) {
    return csc.join('|');
  }
  // const rawCsc = (csc as string).split('|').map((s) => s.trim());
  // if (rawCsc.length === 4) {
  //   return `${rawCsc[0] || ''}/${rawCsc[1] || ''}(${rawCsc[2] || ''}) ${rawCsc[3] || ''}`;
  // }
  return csc;
};

export const toCSCInput = (csc: string) => {
  const rawCSC = csc.split('|');
  const cscNumber = rawCSC.pop();
  return { cscType: [...rawCSC], cscNumber };
};

export const cscPrettyPrint = (value: string) => {
  if (!value) {
    return;
  }

  if (!value.includes('|')) {
    return value;
  }

  const rawCsc = (value as string).split('|').map(s => s.trim());
  return `${rawCsc[0] || ''}/${rawCsc[1] || ''}(${rawCsc[2] || ''}) ${rawCsc[3] || ''}`;
};

export const optionToPrettyPrint = (value: string[]) => {
  const temp = value.map(val => val.split(' - ')).flatMap(val => val.map(i => i.trim()));
  const leaf = temp.pop();
  const short = temp.pop()?.replace(/[()]/g, '');
  return `${temp[0]} / ${short} (${leaf})`;
};
