import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';
import { getCurrentUrlParam } from '../router/router.selector';
import { IUser, Pageable } from '@model';

export const selectUserState = createFeatureSelector<fromUser.UserState>(fromUser.USER_STATE_KEY);

// get the selectors
const { selectIds, selectEntities, selectAll } = fromUser.userAdapter.getSelectors();

export const selectUserIds = createSelector(selectUserState, selectIds);
export const selectUserEntities = createSelector(selectUserState, selectEntities);
export const selectAllUsers = createSelector(selectUserState, selectAll);
export const selectUserTotal = createSelector(selectUserState, state => state.total);

export const getSelectedUser = createSelector(selectUserEntities, getCurrentUrlParam, (userEntities, selectedId) => {
  return (selectedId && userEntities[selectedId]) as IUser;
});

export const selectPagedUsers = createSelector(selectAllUsers, selectUserTotal, (users, total) => ({
  data: [...users],
  total,
}));

export const selectUserPageable = createSelector(
  selectUserState,
  ({ pageIndex, pageSize, sort, filters }): Pageable => ({
    pageIndex,
    pageSize,
    sort: sort!,
    filters,
  })
);
