<div class="sws-confirmation-title">{{ title || 'Warning' }}</div>
<div
  class="sws-confirmation-content"
  [innerHTML]="content || 'Unsaved changes will be lost. Do you want to proceed to another screen?'"></div>
<sws-button-group>
  @if (nzData.onCancel) {
    <button sws-button nz-button swsType="secondary" type="button" nzType="primary" swsSize="xs" (click)="nzData.onCancel()">
      {{ nzData.cancelText || 'Cancel' }}
    </button>
  }
  <button sws-button nz-button swsType="primary" type="button" nzType="primary" swsSize="xs" (click)="nzData.onOk()">
    {{ nzData.okText || 'Yes' }}
  </button>
</sws-button-group>
