<nz-form-item>
  <nz-form-label nzFor="dateOfBirth" [nzRequired]="required">
    <ng-content></ng-content>
    @if(required) {
    <span class="sws-text-required">*</span>
    }
  </nz-form-label>
  <nz-form-control [nzErrorTip]="required ? 'Date of birth is required.' : undefined">
    <nz-date-picker
      nzId="dateOfBirth"
      nzMode="date"
      formControlName="dateOfBirth"
      nzPlaceHolder="Choose date of birth"
      [nzFormat]="swsFormat()"
      [nzShowToday]="false"
      [nzDisabledDate]="disabledDate"
      [nzAllowClear]="false"
    />
  </nz-form-control>
</nz-form-item>

@if(control) {
<nz-form-item class="sws-age-tag">
  <nz-form-label
    >Age<span
      nz-tooltip
      [nzTooltipTitle]="ageTooltipTpl"
      class="sws-text-info"
      nz-icon
      nzType="sws:info-circle"
      nzTheme="fill"
      nzTooltipOverlayClassName="sws-tooltip-overlay"
    ></span
  ></nz-form-label>
  <nz-form-control>
    <nz-tag>{{ control.value | age }}</nz-tag>
  </nz-form-control>
</nz-form-item>
}

<ng-template #ageTooltipTpl>
  <div class="sws-info-hint">Auto-calculated based on date of birth.</div>
</ng-template>
