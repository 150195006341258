import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BaseEntityState, IRole, Pageable, Sort } from '@model';
import * as RoleActions from './role.action';

export const ROLE_STATE_KEY = 'role';

export interface IRoleState extends Omit<Pageable, 'sort'>, BaseEntityState<IRole> {
  sort: Sort | null;
  total: number;
}

const selectRoleId = (role: IRole): number => role.id!;

export const roleAdapter: EntityAdapter<IRole> = createEntityAdapter<IRole>({
  selectId: selectRoleId,
});

export const initialRoleState: IRoleState = roleAdapter.getInitialState({
  pageIndex: 0,
  pageSize: 0,
  sort: null,
  filters: [],
  total: 0,
});

export const roleReducer = createReducer(
  initialRoleState,
  on(RoleActions.loadAllRoles, (state, { pageIndex, pageSize, sort, filters }) => ({
    ...state,
    pageIndex,
    pageSize,
    sort,
    filters,
  })),
  on(RoleActions.loadAllRolesSuccess, (state, { data, total }) => roleAdapter.setAll(data, { ...state, total })),
  on(RoleActions.loadAllRolesFailed, state => roleAdapter.removeAll({ ...state, total: 0 })),
  on(RoleActions.addRoleSuccess, (state, { data }) => roleAdapter.addOne(data, { ...state, total: state.total + 1 })),
  on(RoleActions.removeRoleSuccess, (state, { id }) => roleAdapter.removeOne(id, { ...state, total: state.total - 1 })),
  on(RoleActions.updateRoleSuccess, (state, { type: _, ...role }) => roleAdapter.updateOne(role, { ...state }))
);
