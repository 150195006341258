import { TemplateRef } from '@angular/core';
import { SwsColumnType } from '../datatable';

export interface IDepartment {
  id?: number;
  code: string;
  nameEN: string;
  nameRKE: string;
  address: string;
  description: string | null;
}

export const departmentTableColumns = (actionRef: TemplateRef<IDepartment>): Array<SwsColumnType<IDepartment>> => {
  return [
    {
      key: 'id',
      align: { header: 'center', cell: 'center' },
      width: '8%',
      title: 'ID',
    },
    {
      key: 'code',
      align: { header: 'center', cell: 'center' },
      width: '12%',
      title: 'Department Code',
    },
    {
      key: 'nameEN',
      width: '20%',
      title: 'Department Name',
    },
    {
      key: 'address',
      width: '25%',
      title: 'Department Address',
      align: {
        header: 'center',
        cell: 'center',
      },
    },
    {
      key: 'description',
      // width: '20%',
      title: 'Department Description',
      align: {
        header: 'center',
        cell: 'center',
      },
    },
    {
      key: 'action',
      width: '120px',
      title: 'Action',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};
