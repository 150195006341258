import { Attribute, Component, DestroyRef, forwardRef, HostListener, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NRC_INFO, OnChangeFn, OnTouchedFn } from '@model';
import { Store } from '@ngrx/store';
import { IAppState, selectCitiesAsOptions } from '@store';
import { optionToPrettyPrint } from '@util';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { filter, take } from 'rxjs';

@Component({
  selector: 'sws-input-csc',
  templateUrl: './sws-input-csc.component.html',
  styleUrl: './sws-input-csc.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwsInputCscComponent),
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  standalone: false,
})
export class SwsInputCscComponent implements OnInit, ControlValueAccessor {
  swsId = input('csc');
  swsSpan = input<string | number | null>(null);
  placeholder = input<string[]>(['Choose region', 'Enter nrc/csc number']);

  disabled = false;
  required!: boolean;
  cscNumberPlaceholder!: string;
  cscTypePlaceholder!: string;
  cscOptions: NzCascaderOption[] = [];
  nrcENTypes = NRC_INFO.en;
  nrcMMTypes = NRC_INFO.mm;
  cscValue!: string;
  cscType: string[] | null = [];
  cscNumber!: string | null;
  _onChange!: OnChangeFn<string>;
  _onTouched!: OnTouchedFn;
  constructor(
    @Attribute('formControlName') readonly formControlName: string,
    private readonly rootForm: FormGroupDirective,
    private readonly store: Store<IAppState>,
    private readonly destoryRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCitiesAsOptions)
      .pipe(
        filter(val => val.length > 0),
        take(1),
        takeUntilDestroyed(this.destoryRef)
      )
      .subscribe({
        next: options => {
          this.cscOptions = options;
        },
      });
    this.required = this.currentCsc.hasValidator(Validators.required);
    const [first, second] = this.placeholder();
    this.cscTypePlaceholder = first ? first : ' ';
    this.cscNumberPlaceholder = second ? second : '';
  }

  writeValue(value: string): void {
    this.cscValue = value;
    if (value) {
      const splitVal = value.split('|');
      this.cscType = splitVal.slice(0, splitVal.length - 1);
      this.cscNumber = splitVal.slice(splitVal.length - 1).join('');
    } else {
      this.cscType = null;
      this.cscNumber = null;
    }
  }

  registerOnChange(fn: OnChangeFn<string>): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get currentCsc() {
    return this.rootForm.control.get(this.formControlName) as FormControl;
  }

  onCscTypeChange(_value: string[]) {
    this.markAsDirty();
  }

  onClearCscType() {
    this.markAsDirty();
  }

  onCscNumberChange(_value: string) {
    this.markAsDirty();
  }

  prettyPrint = optionToPrettyPrint;

  markAsDirty() {
    let hasError = false;
    if (this.cscType && this.cscType.length < 3) {
      this._onTouched();
      this.currentCsc.markAsDirty();
      this.currentCsc.setErrors({ ...this.currentCsc.errors, invalidType: true });
      hasError = true;
    }

    if (this.required && !this.cscNumber) {
      this._onTouched();
      this.currentCsc.markAsDirty();
      this.currentCsc.setErrors({ ...this.currentCsc.errors, required: true });
      hasError = true;
    } else {
      const valid = /^(?:[0-9]{6}|[\u1040-\u1049]{6})$/.test(this.cscNumber!);
      if (!valid) {
        this._onTouched();
        this.currentCsc.markAsDirty();
        this.currentCsc.setErrors({ ...this.currentCsc.errors, invalidNumber: true });
        hasError = true;
      }
    }

    if (!hasError) {
      this._onTouched();
      const temp = [...(this.cscType ? this.cscType : []), this.cscNumber].join('|');
      this._onChange(temp);
    }
  }

  get hasError() {
    return (
      this.required &&
      (this.currentCsc.dirty || this.currentCsc.touched) &&
      (this.currentCsc.hasError('required') || this.currentCsc.hasError('invalidType') || this.currentCsc.hasError('invalidNumber'))
    );
  }

  @HostListener('focusout')
  onFocus() {
    this._onTouched();
    this.markAsDirty();
  }
}
