import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState, selectPageableRole } from '@store';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { RoleService } from '@services';
import * as RoleActions from './role.action';

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private router: Router,
    private store: Store<IAppState>
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.loadAllRoles),
      mergeMap(({ pageIndex, pageSize, filters, sort }) => {
        return this.roleService.loadRoles({ pageIndex, pageSize, sort, filters }).pipe(
          map(({ data, total }) => RoleActions.loadAllRolesSuccess({ data: [...data], total })),
          catchError(() => of(RoleActions.loadAllRolesFailed()))
        );
      })
    )
  );

  onAddRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.addRole),
      mergeMap(({ data }) =>
        this.roleService.create(data).pipe(
          map(createdRole => {
            this.router.navigateByUrl('/role');
            return RoleActions.addRoleSuccess({ data: createdRole });
          }),
          catchError(() => of(RoleActions.addRoleFailed()))
        )
      )
    )
  );

  onRemoveRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.removeRole),
      withLatestFrom(this.store.select(selectPageableRole)),
      mergeMap(([{ id }, { pageIndex, pageSize, sort, filters }]) =>
        this.roleService.deleteOne(id).pipe(
          switchMap(() => {
            return [RoleActions.removeRoleSuccess({ id }), RoleActions.loadAllRoles({ pageIndex, pageSize, sort: sort!, filters })];
          }),
          catchError(() => of(RoleActions.removeRoleFailed()))
        )
      )
    )
  );

  onUpdateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.updateRole),
      mergeMap(({ id, role }) =>
        this.roleService.updateOne(id, role).pipe(
          map(({ ...data }) => {
            this.router.navigateByUrl('/role');
            return RoleActions.updateRoleSuccess({
              changes: {
                ...data,
              },
              id: id!,
            });
          }),
          catchError(() => of(RoleActions.updateRoleFailed()))
        )
      )
    )
  );
}
