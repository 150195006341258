<form
  nz-form
  novalidate
  nzLayout="vertical"
  [nzNoColon]="true"
  [formGroup]="form"
  #f="ngForm"
  (ngSubmit)="submitChildrenForm($event, f.form)"
  class="application-form">
  <nz-row nzJustify="space-between">
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fullName" nzRequired> Full Name (Rakhine) <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Full Name (Rakhine) is required.">
          <input nz-input sws-input formControlName="fullName" type="text" name="fullName" id="fullName" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-dob formControlName="dateOfBirth">Date Of Birth</sws-input-dob>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="relationType" nzRequired
          >Genealogical Type
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Genealogical type is required.">
          <input sws-input nz-input formControlName="relationType" type="text" name="relationType" id="relationType" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="gender" nzRequired>Gender <span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Gender is required.">
          <nz-radio-group id="gender" nzName="gender" formControlName="gender">
            @for (g of genderTypes; track g.id) {
              <label [for]="g.label" sws-radio nz-radio [nzValue]="g.value">{{ g.label }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-form-item>
    <nz-form-label nzFor="education" nzRequired>Education <span class="sws-text-required">*</span> </nz-form-label>
    <nz-form-control nzErrorTip="Highest education is required.">
      <input sws-input nz-input formControlName="education" type="text" name="education" id="education" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="occupation" nzRequired>Occupation <span class="sws-text-required">*</span> </nz-form-label>
    <nz-form-control nzErrorTip="Occupation is required.">
      <input sws-input nz-input formControlName="occupation" type="text" name="occupation" id="occupation" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="address" nzRequired>Address <span class="sws-text-required">*</span> </nz-form-label>
    <nz-form-control nzErrorTip="Address is required.">
      <sws-textarea id="address" formControlName="address" placeholder="Enter address"></sws-textarea>
    </nz-form-control>
  </nz-form-item>
  <sws-button-group>
    <button sws-button swsType="primary" type="submit" nz-button nzType="primary">Submit</button>
  </sws-button-group>
</form>
