import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  template: `
    <ng-template #nzIndicatorTpl>
      <span class="ant-spin-dot global-spinner">
        <span nz-icon nzType="sync" nzSpin></span>
      </span>
    </ng-template>
  `,
  standalone: false,
})
export class GlobalIndicatorComponent {
  @ViewChild('nzIndicatorTpl', { static: true })
  nzIndicator!: TemplateRef<void>;
}
