import { Attribute, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { EducationFormComponent } from '@components';
import { EducationStatus, IEducationInfo, qualificationTableColumns, SwsColumnType } from '@model';
import { Store } from '@ngrx/store';
import { ApplicationFormService, CommonService } from '@services';
import { getSelectedApplicationFormStatus, IAppState } from '@store';
import { SWS_MODAL_FORM } from '@util';

@Component({
  selector: 'sws-education-info',
  templateUrl: './education-info.component.html',
  styleUrls: ['./education-info.component.scss'],
  standalone: false,
})
export class EducationInfoComponent implements OnInit {
  formStatus$ = inject(Store<IAppState>).select(getSelectedApplicationFormStatus);

  form!: FormArray;
  @ViewChild('actionRef', { static: true })
  actionRef!: TemplateRef<IEducationInfo>;
  @ViewChild('titleRef', { static: true })
  titleRef!: TemplateRef<IEducationInfo>;
  @ViewChild('durationValueRef', { static: true })
  durationValueRef!: TemplateRef<IEducationInfo>;
  @ViewChild('statusRef', { static: true })
  statusRef!: TemplateRef<IEducationInfo>;

  columns!: Array<SwsColumnType<IEducationInfo>>;

  educationStatus!: EducationStatus[];

  useModalForm = inject(SWS_MODAL_FORM);
  constructor(
    @Attribute('formArrayName') readonly formArrayName: string,
    private readonly _rootForm: FormGroupDirective,
    private readonly _appFormService: ApplicationFormService,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.educationStatus = this._commonService.educationStatus;
    this.form = this._rootForm.control.get(this.formArrayName) as FormArray;
    this.columns = qualificationTableColumns(this.actionRef, { title: this.titleRef, value: this.durationValueRef }, this.statusRef);
  }

  get qualifications(): IEducationInfo[] {
    return this.form?.value || [];
  }

  createNewEducationInfoForm(): FormGroup {
    return this._appFormService.createEducationForm();
  }

  addNewEducationInfo = () => this.useModalForm('Educational Qualification', EducationFormComponent, this.form);

  trackByName(_: number, item: IEducationInfo) {
    return item.name;
  }

  remove(index: number) {
    this.form.removeAt(index);
  }
}
