<nz-page-header class="sws-page-header">
  <nz-page-header-title>
    {{ pageTitle$ | async }}
  </nz-page-header-title>
  @if (to().length) {
  <nz-page-header-extra>
    <button type="button" sws-button [swsType]="type()" (click)="handleBack()" swsSize="sm">{{ text() }}</button>
    <ng-content></ng-content>
  </nz-page-header-extra>
  }
</nz-page-header>
