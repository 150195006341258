<table class="sws-table">
  <ng-container [ngTemplateOutlet]="swsTable" [ngTemplateOutletContext]="{ columns: columns, data: data }"></ng-container>
</table>

@if (swsMode() === 'data-table') {
  <nz-row [nzJustify]="'end'" [nzAlign]="'middle'" class="sws-pagination-container">
    <nz-pagination
      [nzItemRender]="itemRenderTpl"
      [nzTotal]="total"
      [nzShowTotal]="totalTpl"
      [nzPageSizeOptions]="pageSizeOptions"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzPageIndexChange)="onPageIndexChange($event)"
      [nzHideOnSinglePage]="false">
      <ng-template #totalTpl let-range="range" let-total>
        @if (range[0] < range[1]) {
          {{ range[0] }}-{{ range[1] }} of {{ total }} items
        } @else {
          {{ range[0] }} of {{ total }} items
        }
      </ng-template>

      <ng-template #itemRenderTpl let-type let-page="page">
        @switch (type) {
          @case ('page') {
            <a class="sws-pagination-item">{{ page }}</a>
          }
          @case ('prev') {
            <button type="button" class="sws-pagination-item-link">
              <span nz-icon nzType="left" nzTheme="outline"></span>
            </button>
          }
          @case ('next') {
            <button type="button" class="sws-pagination-item-link">
              <span nz-icon nzType="right" nzTheme="outline"></span>
            </button>
          }
          @case ('prev_5') {
            <button type="button" class="sws-pagination-item-link">
              <span nz-icon nzType="double-left" nzTheme="outline"></span>
            </button>
          }
          @case ('next_5') {
            <button type="button" class="sws-pagination-item-link">
              <span nz-icon nzType="double-right" nzTheme="outline"></span>
            </button>
          }
        }
      </ng-template>
    </nz-pagination>
    <nz-input-group [nzCompact]="true" class="sws-page-options">
      <div>show</div>
      <nz-select
        class="sws-page-options-size-changer"
        [nzShowSearch]="false"
        [nzMode]="'default'"
        [ngModel]="pageSize"
        [nzDropdownClassName]="'sws-page-options-dropdown'"
        (ngModelChange)="onPageSizeChange($event)">
        @for (size of pageSizeOptions; track size) {
          <nz-option [nzLabel]="size" [nzValue]="size" [nzKey]="size"></nz-option>
        }
      </nz-select>
      <div>items / page</div>
    </nz-input-group>
  </nz-row>
}

<ng-template #swsTable let-columns="columns" let-data="data">
  @for (column of columns; track column.key) {
    <col [style]="{ width: column.width, 'min-width': column.width }" [accessKey]="column.key" />
  }
  <thead>
    <tr>
      @for (column of columns; track column.key) {
        @if (isTemplateRef(column.title)) {
          <th>
            <ng-container [ngTemplateOutlet]="column.title.transform"></ng-container>
          </th>
        } @else {
          <th>{{ column.title }}</th>
        }
      }
    </tr>
  </thead>
  <tbody>
    @for (item of data; track item.id || idx; let idx = $index) {
      <tr>
        @for (column of columns; track column.key) {
          @if (isTemplateRef(column.value)) {
            <td>
              <ng-container [ngTemplateOutlet]="column.value.transform" [ngTemplateOutletContext]="{ $implicit: item, index: idx }"></ng-container>
            </td>
          } @else if (getType(column.value) === 'function') {
            <td>{{ column.value.transform(item[column.key]) || '&mdash;' }}</td>
          } @else {
            <td>{{ item[column.key] || '&mdash;' }}</td>
          }
        }
      </tr>
    } @empty {
      <tr>
        <td [attr.colspan]="columns.length" class="sws-table-placeholder">
          <nz-empty [nzNotFoundContent]="">No Data</nz-empty>
        </td>
      </tr>
    }
  </tbody>
</ng-template>
