import { Component, input } from '@angular/core';
import { NzJustify } from 'ng-zorro-antd/grid';

@Component({
  selector: 'sws-button-group',
  templateUrl: './sws-button-group.component.html',
  styleUrl: './sws-button-group.component.scss',
  standalone: false,
})
export class SwsButtonGroupComponent {
  swsJustify = input<NzJustify>('center');
}
