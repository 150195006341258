<ng-container [formGroup]="form">
  <sws-page-subtitle>
    <ng-template sws-title>Applicant Personal Information</ng-template>
  </sws-page-subtitle>
  <nz-row nzJustify="space-between">
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fullNameRKE" nzRequired>
          Full Name &nbsp;<span class="sws-text-muted">(Rakhine)</span>
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Full name (Rakhine) is required.">
          <input
            sws-input
            nz-input
            id="fullNameRKE"
            name="fullNameRKE"
            type="text"
            formControlName="fullNameRKE"
            placeholder="Enter full name (Rakhine)" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fullNameEN" nzRequired
          >Full Name &nbsp;<span class="sws-text-muted">(English)</span>
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Full name (English) is required.">
          <input
            sws-input
            nz-input
            id="fullNameEN"
            name="fullNameEN"
            type="text"
            formControlName="fullNameEN"
            placeholder="Enter full name (English)" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="otherName"> Other Name&nbsp;<span class="sws-text-muted">(Optional)</span> </nz-form-label>
        <nz-form-control>
          <input
            sws-input
            nz-input
            id="otherName"
            name="otherName"
            type="text"
            formControlName="otherName"
            placeholder="Enter other name (Optional)" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fatherName" nzRequired>Father's Name <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Father's name is required.">
          <input sws-input nz-input id="fatherName" name="fatherName" type="text" formControlName="fatherName" placeholder="Enter father's name" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-csc formControlName="currentCSC"> NRC / CSC </sws-input-csc>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="occupation" nzRequired>Occupation <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Occupation is required.">
          <input id="occupation" name="occupation" type="text" sws-input nz-input formControlName="occupation" placeholder="Enter occupation" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <sws-input-dob formControlName="dateOfBirth">Date Of Birth</sws-input-dob>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="gender" nzRequired>Gender <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Gener is required.">
          <nz-radio-group formControlName="gender" nzName="gender" id="gender">
            @for (g of genderTypes; track g.id) {
              <label [for]="g.label" sws-radio nz-radio [nzValue]="g.value">{{ g.label }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="genderOrientation" nzRequired>Interested In <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Gender orientation is required.">
          <nz-radio-group formControlName="genderOrientation" id="genderOrientation" nzName="genderOrientation">
            @for (g of sortById(sexualities); track g.id) {
              <label [for]="g.label" sws-radio nz-radio [nzValue]="g.value">{{ g.label }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="maritalStatus" nzRequired>Marital Status <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Marital status is required.">
          <nz-radio-group formControlName="maritalStatus" (ngModelChange)="onMaritalStatusChange($event)">
            @for (status of maritalStatus; track $index) {
              <label [for]="status" sws-radio nz-radio [nzValue]="status">{{ status | titlecase }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="race" nzRequired>Race <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Race is required.">
          <input id="race" name="race" type="text" sws-input nz-input formControlName="race" placeholder="Enter race" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="religion" nzRequired>Religion <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Religion is required.">
          <input id="religion" name="religion" type="text" sws-input nz-input formControlName="religion" placeholder="Enter religion" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="prominentSign" nzRequired>Prominent Mark / Sign<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Prominent mark or sign is required.">
          <input
            sws-input
            nz-input
            id="prominentSign"
            name="prominentSign"
            type="text"
            formControlName="prominentSign"
            placeholder="Enter prominent mark or sign" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-row nzJustify="space-between">
        <nz-col nzSpan="11">
          <nz-form-item>
            <nz-form-label nzFor="height" nzRequired
              >Height &nbsp;<span class="sws-text-muted">(Feet & Inches)</span>
              <span class="sws-text-required">*</span>
            </nz-form-label>
            <nz-form-control nzErrorTip="Height is required.">
              <nz-cascader
                nzTriggerAction="click"
                nzExpandTrigger="click"
                [nzLabelRender]="heightRender"
                [nzOptions]="heightOptions"
                [nzAllowClear]="false"
                [nzSuffixIcon]="'caret-down'"
                [nzShowSearch]="false"
                formControlName="height"
                nzPlaceHolder="Choose height">
              </nz-cascader>
              <ng-template #heightRender let-options="labels" let-selectedOptions="selectedOptions">
                @for (option of options; track $index; let first = $first, last = $last) {
                  @if (!last) {
                    {{ option[0] }} '
                  } @else {
                    {{ option[0] }} "
                  }
                }
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="11">
          <nz-form-item>
            <nz-form-label nzFor="weight" nzRequired
              >Weight &nbsp;<span class="sws-text-muted">(Pound)</span>
              <span class="sws-text-required">*</span>
            </nz-form-label>
            <nz-form-control>
              <nz-input-number
                name="weight"
                nzId="weight"
                [nzFormatter]="weightFormatter"
                [nzParser]="weightParser"
                nzPlaceHolder="Enter weight"
                formControlName="weight"
                sws-number-only
                [nzDisabled]="form.controls['weight'].disabled" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="skinColor" nzRequired>
          Skin Color
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Skin color is required.">
          <input sws-input nz-input id="skinColor" name="skinColor" type="text" formControlName="skinColor" placeholder="Enter skin color" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="bloodType" nzRequired>
          Blood Group
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Blood type is required.">
          <nz-select nzId="bloodType" sws-select formControlName="bloodType" nzPlaceHolder="Choose blood type" [nzAllowClear]="false">
            @for (type of bloodTypes; track type) {
              <nz-option [nzLabel]="type" [nzValue]="type"></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-phonenumber id="firstPhoneNumber" swsRequired="Permanent phone number is required." formControlName="firstPhoneNumber">
          Permanent Contact No
        </sws-input-phonenumber>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-phonenumber id="secondPhoneNumber" formControlName="secondPhoneNumber">
          Secondary Contact No&nbsp;<span class="sws-text-muted">(Optional)</span>
        </sws-input-phonenumber>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="email" nzRequired>Email <span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Email is required.">
          <input id="email" name="email" sws-input nz-input type="email" formControlName="email" placeholder="e.g: name@example.com" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="permanentAddress" nzRequired>Permanent Address<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Permanent address is required.">
          <sws-textarea id="permanentAddress" formControlName="permanentAddress" placeholder="Enter Permanent Address" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="currentAddress" nzRequired>Current Address<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Current address is required.">
          <sws-textarea id="currentAddress" placeholder="Enter Current Address" formControlName="currentAddress" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</ng-container>
