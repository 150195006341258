<form
  nz-form
  novalidate
  nzLayout="vertical"
  [nzNoColon]="true"
  [formGroup]="form"
  #f="ngForm"
  (ngSubmit)="onSubmit($event, f.form)"
  class="application-form">
  <nz-row nzJustify="space-between">
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="position" nzRequired>Position (Work as) <span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Work position is required.">
          <input sws-input nz-input formControlName="position" type="text" id="position" name="position" placeholder="Enter position" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="organization" nzRequired>Company / Organization<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Organization is required.">
          <input
            sws-input
            nz-input
            formControlName="organization"
            type="text"
            id="organization"
            name="organization"
            placeholder="Enter organization" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="servedYears" nzRequired>Years<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Service duration is required.">
          <nz-range-picker
            nzId="servedYears"
            nzMode="date"
            nzFormat="dd-MM-yyyy"
            formControlName="duration"
            [nzShowToday]="false"
            [nzDisabledDate]="disabledDate"
            [nzPlaceHolder]="['From', 'To']" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-form-item>
    <nz-form-label nzFor="duty">Duty / Responsibility <span class="sws-text-required">*</span></nz-form-label>
    <nz-form-control nzErrorTip="Duty or Responsibility is required.">
      <sws-textarea id="duty" formControlName="duty" placeholder="Enter Duty Or Responsibility"></sws-textarea>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="remark">Remark&nbsp;<span class="sws-text-muted"> (Optional) </span></nz-form-label>
    <nz-form-control>
      <sws-textarea id="remark" formControlName="remark" placeholder="Enter Remark"></sws-textarea>
    </nz-form-control>
  </nz-form-item>

  <sws-button-group>
    <button sws-button swsType="primary" type="submit" nz-button nzType="primary">Submit</button>
  </sws-button-group>
</form>
