import { Attribute, Component, forwardRef, HostListener, input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { COUNTRIES_DATA } from '@data';
import { OnChangeFn, OnTouchedFn } from '@model';
import { replaceMmDigitWithEn } from '@util';
import parsePhoneNumberFromString, { isValidPhoneNumber, PhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'sws-input-phonenumber',
  templateUrl: './sws-input-phonenumber.component.html',
  styleUrl: './sws-input-phonenumber.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwsInputPhoneNumberComponent),
    },
  ],
  standalone: false,
})
export class SwsInputPhoneNumberComponent implements OnInit, ControlValueAccessor {
  disabled = false;
  required = false;
  countryCode = input<string>('+95');
  placeholder = input<string>('9442812345');
  swsRequired = input<string>('Phone number is required.');
  countries = COUNTRIES_DATA;
  selectedCountry!: string;
  numberInput!: string;
  _onChange!: OnChangeFn<string>;
  _onTouched!: OnTouchedFn;

  constructor(
    @Attribute('formControlName') private readonly formControlName: string,
    @Attribute('id') readonly id: string,
    private readonly rootForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.required = this.phoneNumber.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    if (value) {
      let temp: string | PhoneNumber = replaceMmDigitWithEn(value);
      temp = parsePhoneNumberFromString(value) as PhoneNumber;
      const idx = this.countries.findIndex(ctry => ctry.code === temp?.country && ctry.dial_code.includes(temp.countryCallingCode));
      if (idx) {
        this.selectedCountry = this.countries[idx].dial_code;
      }

      if (temp?.nationalNumber) {
        this.numberInput = temp.nationalNumber;
      }
    } else {
      this.selectedCountry = this.countryCode();
    }
  }

  registerOnChange(fn: OnChangeFn<string>): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get phoneNumber() {
    return this.rootForm.control.get(this.formControlName) as FormControl;
  }

  onCountrySelect() {
    if (!this.numberInput) {
      this._onChange(this.selectedCountry);
    }
    this.markAsDirty();
  }

  onNumberInputChange() {
    this.markAsDirty();
  }

  markAsDirty() {
    if (this.phoneNumber.touched) {
      this.phoneNumber.markAsDirty();
    }
    if (!this.numberInput) {
      this.phoneNumber.setValue('', { emitEvent: false });
      this.phoneNumber.updateValueAndValidity();
      return;
    }
    if (/^[0\u1040]/.test(this.numberInput.charAt(0))) {
      this.numberInput = this.numberInput.replace(/^[0\u1040]/, '');
    }
    const sanitized = replaceMmDigitWithEn(this.numberInput);
    const value = this.selectedCountry.concat(sanitized);
    if (!isValidPhoneNumber(value)) {
      this.phoneNumber.setErrors({ ...this.phoneNumber.errors, invalidNumber: true });
    } else {
      this._onChange(value);
    }
  }

  @HostListener('focusout')
  onFocus() {
    this._onTouched();
    this.markAsDirty();
  }
}
