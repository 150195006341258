import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, input, OnInit, output, ViewEncapsulation } from '@angular/core';
import { SwsTableRecordType, SwsColumnType, TransformType, SwsTableQueryParams, SwsTableMode } from '@model';

@Component({
  selector: 'sws-data-table',
  templateUrl: './sws-data-table.component.html',
  styleUrl: './sws-data-table.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SwsDataTableComponent<T = SwsTableRecordType> implements OnInit {
  private _data: T[] = [];
  private _total = 0;
  private _columns: Array<SwsColumnType<T>> = [];

  @Input()
  set data(value: T[]) {
    this._data = value;
    this.cdr.markForCheck();
  }

  get data(): T[] {
    return this._data;
  }

  @Input()
  set total(value: number) {
    this._total = value;
    this.cdr.markForCheck();
  }

  get total(): number {
    return this._total;
  }

  @Input()
  set columns(value: Array<SwsColumnType<T>>) {
    this._columns = value;
    this.cdr.markForCheck();
  }

  get columns(): Array<SwsColumnType<T>> {
    return this._columns;
  }

  swsMode = input<SwsTableMode>('data-table');
  paramChange = output<SwsTableQueryParams>({ alias: 'change' });

  pageSizeOptions = [10, 20, 50];
  pageSize = this.pageSizeOptions[0];
  pageIndex = 1;
  sort: SwsTableQueryParams['sort'] = [
    {
      key: 'id',
      value: 'descend',
    },
  ];
  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.swsMode() !== 'simple') {
      this.paramChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize, sort: this.sort });
    }
  }

  isTemplateRef(transformType: TransformType) {
    return typeof transformType === 'object' && transformType.type === 'template';
  }

  getType(transformType: TransformType) {
    if (typeof transformType === 'object') {
      return transformType.type;
    }
    return typeof transformType;
  }

  onPageSizeChange(size: number) {
    this.pageSize = size;
    this.paramChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize, sort: this.sort });
  }

  onPageIndexChange(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.paramChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize, sort: this.sort });
  }
}
