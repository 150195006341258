import { Component, contentChild } from '@angular/core';
import { SwsTitleDirective } from '@directives';

@Component({
  selector: 'sws-page-subtitle',
  templateUrl: './sws-page-subtitle.component.html',
  styleUrl: './sws-page-subtitle.component.scss',
  standalone: false,
})
export class SwsPageSubtitleComponent {
  content = contentChild(SwsTitleDirective);
  constructor() {}
}
