import { Component, inject, OnInit, output } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { BloodType, GenderType, DEFAULT_HEIGHT_OPTIONS, MaritalStatus } from '@model';
import { range as _range } from 'lodash';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { CommonService } from '@services';

@Component({
  selector: 'sws-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  standalone: false,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class PersonalInfoComponent implements OnInit {
  form!: FormGroup;
  genderTypes!: GenderType[];
  sexualities!: GenderType[];
  bloodTypes!: BloodType[];
  maritalStatus!: MaritalStatus[];
  tabs = [
    { index: 0, title: 'Applicant Information' },
    { index: 1, title: 'Family Members' },
  ];

  heightOptions: NzCascaderOption[] = DEFAULT_HEIGHT_OPTIONS;

  maritalStatusChange = output<MaritalStatus>();
  constructor(
    private readonly rootForm: FormGroupDirective,
    private readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.form = this.rootForm.form;
    this.genderTypes = this.commonService.genderTypes;
    this.sexualities = this.commonService.sexualities;
    this.bloodTypes = this.commonService.bloodTypes;
    this.maritalStatus = this.commonService.martialStatus;
    if (this.applicantMaritalStatus === MaritalStatus.MARRIED) {
      this.onMaritalStatusChange(MaritalStatus.MARRIED);
    }
  }

  sortById = this.commonService.sortById;
  disabledDate = this.commonService.disabledDate;

  weightFormatter = (value: number): string => (value ? `${value} lb` : ``);
  weightParser = (value: string): number => +value.replace(' lb', '');

  onMaritalStatusChange(status: MaritalStatus) {
    this.maritalStatusChange.emit(status);
  }

  get applicantMaritalStatus(): string {
    return this.form.controls['maritalStatus']?.value;
  }
}
