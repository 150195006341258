import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTable, IDepartment, Pageable } from '@model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(`/department/all`);
  }

  loadDepartments(pageable: Pageable): Observable<DataTable<IDepartment>> {
    return this.http.post<DataTable<IDepartment>>(`/department/all`, { ...pageable });
  }

  create(department: IDepartment): Observable<IDepartment> {
    return this.http.post<IDepartment>('/department/create', { ...department });
  }

  deleteOne(id: number): Observable<void> {
    return this.http.delete<void>(`/department/${id}/remove`);
  }

  updateOne(id: number, department: IDepartment): Observable<IDepartment> {
    return this.http.put<IDepartment>(`/department/${id}/update`, { ...department });
  }

  search(name: string): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(`/department/search?name=${name}`);
  }
}
