import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse, IApiResponse, IUser, UpdatePassword } from '@model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<IApiResponse<AuthResponse>> {
    return this.http.post<IApiResponse<AuthResponse>>('/login', { username, password });
  }

  getPrincipal(): Observable<IUser> {
    return this.http.get<IUser>('/principal');
  }

  logout(): Observable<void> {
    return this.http.post<void>('/logout', null);
  }

  updateGeneratedPass(data: UpdatePassword): Observable<{ data: string }> {
    return this.http.post<{ data: string }>('/change-password', { ...data });
  }

  reset(loginId: string) {
    return this.http.post<IApiResponse<string>>(`/reset-password?q=${loginId}`, null);
  }
}
