import { Attribute, Component, HostListener, inject } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnChangeFn, OnTouchedFn } from '@model';

@Component({
  selector: 'sws-textarea',
  templateUrl: './sws-textarea.component.html',
  styleUrl: './sws-textarea.component.scss',
  standalone: false,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwsTextareaComponent,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SwsTextareaComponent implements ControlValueAccessor {
  _onChange: OnChangeFn<string> = () => {};
  _onTouched: OnTouchedFn = () => {};

  private value!: string;
  private disabled = false;
  constructor(
    @Attribute('formControlName') readonly formControlName: string,
    @Attribute('placeholder') readonly placeholder: string,
    @Attribute('id') readonly id: string,
    private readonly rootForm: FormGroupDirective
  ) {}

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: OnChangeFn<string>): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get control() {
    return this.rootForm.control.get(this.formControlName) as FormControl;
  }

  onClear() {
    this._onTouched();
    this.control.setValue(null);
    this.control.markAsDirty();
  }

  @HostListener('focusout')
  onFocus() {
    this._onTouched();
    this.control.markAsDirty();
  }
}
