import { Pipe, PipeTransform } from '@angular/core';
import { cscPrettyPrint } from '@util';

@Pipe({
  name: 'csc',
  standalone: false,
})
export class SwsCSCPipe implements PipeTransform {
  transform(cscString: string): string | void {
    return cscPrettyPrint(cscString);
  }
}
