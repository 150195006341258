import { ActivatedRouteSnapshot, RouterStateSnapshot, Data, Params } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { MergedRoute } from './merged-route';

export class MergedRouterStateSerializer implements RouterStateSerializer<MergedRoute> {
  serialize(routerState: RouterStateSnapshot): MergedRoute {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data: mergeRouteData(routerState.root),
      title: mergeRouteTitle(routerState.root),
    };
  }
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild!;
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
}

function mergeRouteTitle(route: ActivatedRouteSnapshot): string {
  if (!route) {
    return '';
  }
  const currentTitle = route.routeConfig?.title as string;
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild!;
  return currentTitle ? currentTitle : mergeRouteTitle(primaryChild);
}

function mergeRouteData(route: ActivatedRouteSnapshot): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild!;
  return { ...currentData, ...mergeRouteData(primaryChild) };
}
