import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[sws-checkbox-group]',
  standalone: false,
})
export class SwsCheckboxGroupDirective implements OnInit {
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add('sws-checkbox-group');
  }
}
