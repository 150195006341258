import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDepartment from './department.reducer';
import { getCurrentUrlParam } from '../router/router.selector';
import { IDepartment, Pageable } from '@model';

export const selectDepartmentState = createFeatureSelector<fromDepartment.IDepartmentState>(fromDepartment.DEPARTMENT_STATE_KEY);

// get the selectors
const { selectIds, selectEntities, selectAll } = fromDepartment.departmentAdapter.getSelectors();

export const selectDepartmentIds = createSelector(selectDepartmentState, selectIds);
export const selectDepartmentEntities = createSelector(selectDepartmentState, selectEntities);
export const selectAllDepartments = createSelector(selectDepartmentState, selectAll);
export const selectDepartmentTotal = createSelector(selectDepartmentState, state => state.total);

export const getSelectedDepartment = createSelector(
  selectDepartmentEntities,
  getCurrentUrlParam,
  (DepartmentEntites, selectedId) => (selectedId && DepartmentEntites[selectedId]) as IDepartment
);

export const selectDepartments = createSelector(selectAllDepartments, selectDepartmentTotal, (deps, total) => ({
  data: [...deps],
  total,
}));

export const selectDepartmentPageable = createSelector(
  selectDepartmentState,
  ({ pageIndex, pageSize, sort, filters }): Pageable => ({
    pageIndex,
    pageSize,
    sort: sort!,
    filters,
  })
);
