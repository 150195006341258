import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BaseEntityState, IQuestion, Pageable } from '@model';
import * as DocQuestActions from './docs-quest.action';

export const DOCUMENT_QUEST_STATE_KEY = 'docs_quest';

export interface IDocQuestionState extends Omit<Pageable, 'sort'>, BaseEntityState<IQuestion> {
  total: number;
}

const selectDocQuestId = (setting: IQuestion): number => setting.id!;

export const docQuestAdapter: EntityAdapter<IQuestion> = createEntityAdapter<IQuestion>({
  selectId: selectDocQuestId,
});

export const initialDocQuestState: IDocQuestionState = docQuestAdapter.getInitialState({
  pageIndex: 0,
  pageSize: 0,
  sort: null,
  filters: [],
  total: 0,
});

export const docsQuestReducer = createReducer(
  initialDocQuestState,
  on(DocQuestActions.loadPageableDocsQuest, (state, { pageIndex, pageSize, sort, filters }) => ({
    ...state,
    pageIndex,
    pageSize,
    sort,
    filters,
  })),
  on(DocQuestActions.loadPageableDocsQuestSuccess, (state, { data, total }) => docQuestAdapter.setAll(data, { ...state, total })),
  on(DocQuestActions.loadPageableDocsQuestFailed, state => docQuestAdapter.removeAll({ ...state, total: 0 })),
  on(DocQuestActions.loadAllDocsQuestSuccess, (state, { data }) => docQuestAdapter.setAll(data, { ...state })),
  on(DocQuestActions.addDocsQuestSuccess, (state, { type, ...data }) => docQuestAdapter.addOne({ ...data }, { ...state, total: state.total + 1 })),
  on(DocQuestActions.updateDocsQuestSuccess, (state, { type, ...data }) => docQuestAdapter.updateOne(data, { ...state }))
);
