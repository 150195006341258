import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDocQuest from './docs-quest.reducer';
import { getCurrentUrlParam } from '../router';
import { IQuestion } from '@model';

export const selectDocumentQuestState = createFeatureSelector<fromDocQuest.IDocQuestionState>(fromDocQuest.DOCUMENT_QUEST_STATE_KEY);

// get the selectors
const { selectIds, selectEntities, selectAll } = fromDocQuest.docQuestAdapter.getSelectors();

export const selectDocumentQuestIds = createSelector(selectDocumentQuestState, selectIds);
export const selectDocumentQuestEntities = createSelector(selectDocumentQuestState, selectEntities);
export const selectAllDocumentQuests = createSelector(selectDocumentQuestState, selectAll);
export const selectDocumentQuestTotal = createSelector(selectDocumentQuestState, state => state.total);
export const selectPagedDocsQuestion = createSelector(selectAllDocumentQuests, selectDocumentQuestTotal, (quests, total) => ({
  data: [...quests],
  total,
}));

export const getSelectedDocumentQuest = createSelector(
  selectDocumentQuestEntities,
  getCurrentUrlParam,
  (entities, selectedId) => (selectedId && entities[selectedId]) as IQuestion
);
