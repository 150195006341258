import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTable, IRole, Pageable } from '@model';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<IRole[]> {
    return this.http.get<IRole[]>(`/role/all`);
  }

  loadRoles(pageable: Pageable): Observable<DataTable<IRole>> {
    return this.http.post<DataTable<IRole>>(`/role/all`, {
      ...pageable,
    });
  }

  create(role: IRole): Observable<IRole> {
    return this.http.post<IRole>('/role/create', { ...role });
  }

  deleteOne(id: number): Observable<void> {
    return this.http.delete<void>(`/role/${id}/remove`);
  }

  updateOne(id: number, role: IRole): Observable<IRole> {
    return this.http.put<IRole>(`/role/${id}/update`, { ...role });
  }

  search(name: string): Observable<IRole[]> {
    return this.http.get<IRole[]>(`/role/search?name=${name}`);
  }
}
