import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationFormService } from '@services';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'sws-supporter-form',
  templateUrl: './supporter-form.component.html',
  styleUrl: './supporter-form.component.scss',
  standalone: false,
})
export class SupporterFormComponent implements OnInit {
  form!: FormGroup;

  nzData: { data: FormArray } = inject<{ data: FormArray }>(NZ_MODAL_DATA);

  constructor(
    private readonly appFormService: ApplicationFormService,
    private readonly modalRef: NzModalRef<SupporterFormComponent, { data: FormArray }>
  ) {}

  ngOnInit(): void {
    this.form = this.appFormService.createSupporterForm();
  }

  get isMember(): FormControl {
    return this.form.get('isMember') as FormControl;
  }

  get memberSerialNo(): FormControl {
    return this.form.get('memberSerialNo') as FormControl;
  }

  onMemberStatusChange(event: boolean) {
    if (event) {
      this.memberSerialNo.addValidators(Validators.required);
    } else {
      this.memberSerialNo.removeValidators(Validators.required);
    }
    this.memberSerialNo.updateValueAndValidity({ onlySelf: true });
  }

  onSubmit(event: SubmitEvent, data: FormGroup) {
    console.log(data);
    event.preventDefault();
    if (data.invalid) {
      Object.entries(data.controls).forEach(([key, control]) => {
        if (key === 'memberSerialNo' && !control.value && data.controls['isMember'].value == 1) {
          control.setErrors({ ...control.errors, required: true }, { emitEvent: true });
          control.markAsDirty();
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.nzData.data.push(data);
    this.modalRef.close(this.nzData);
  }
}
