import { SwsAgePipe } from './age.pipe';
import { SwsCSCPipe } from './csc.pipe';
import { SwsMmDigitPipe } from './mm-digit.pipe';
import { SwsPhoneNumberPipe } from './phone-number.pipe';

export * from './age.pipe';
export * from './csc.pipe';
export * from './mm-digit.pipe';
export * from './phone-number.pipe';

const SWS_PIPES = [SwsAgePipe, SwsCSCPipe, SwsPhoneNumberPipe, SwsMmDigitPipe];
export default SWS_PIPES;
