import { createAction, props } from '@ngrx/store';
import { IQuestion, Pageable } from '@model';
import { Update } from '@ngrx/entity';

// pageable
export const loadPageableDocsQuest = createAction('[setting|doc] Load Pageable Document Quest', props<Pageable>());
export const loadPageableDocsQuestSuccess = createAction(
  '[setting|doc] Load Pageable Document Quest Success',
  props<{ data: IQuestion[]; total: number }>()
);
export const loadPageableDocsQuestFailed = createAction('[setting|doc] Load Pageable Document Quest Failed');

// loadAll
// pageable
export const loadAllDocsQuest = createAction('[setting|doc] Load All Document Quest');
export const loadAllDocsQuestSuccess = createAction('[setting|doc] Load All Document Quest Success', props<{ data: IQuestion[] }>());
export const loadAllDocsQuestFailed = createAction('[setting|doc] Load All Document Quest Failed');

// create
export const addDocsQuest = createAction('[setting|doc] Add Document Quest', props<IQuestion>());
export const addDocsQuestSuccess = createAction('[setting|doc] Add Document Quest Success', props<IQuestion>());
export const addDocsQuestFailed = createAction('[setting|doc] Add Document Failed');

// update
export const updateDocsQuest = createAction('[setting|doc] Update Document Setting', props<IQuestion>());
export const updateDocsQuestSuccess = createAction('[setting|doc] Update All Document Setting Success', props<Update<IQuestion>>());
export const updateDocsQuestFailed = createAction('[setting|doc] Update Document Setting Failed');
