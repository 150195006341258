import { Attribute, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer } from '@angular/forms';
import { IDepartment } from '@model';
import { CommonService, DepartmentService } from '@services';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'sws-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss'],
  standalone: false,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class ApplicationInfoComponent implements OnInit {
  searchChange$ = new BehaviorSubject<string>('');
  departments$!: Observable<IDepartment[]>;
  isLoading = false;
  init = false;
  disabledDate = inject(CommonService).disabledDate;
  constructor(
    @Attribute('formGroupName') readonly formGroupName: string,
    private readonly departmentService: DepartmentService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.init = true;
    this.departments$ = this.searchChange$.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((name: string) =>
        this.departmentService.search(name).pipe(
          tap(() => {
            this.isLoading = false;
            this.init = false;
          }),
          catchError(() => {
            this.isLoading = false;
            this.init = false;
            return of([]);
          })
        )
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  onSearchDepartment(depName: string) {
    if (depName) {
      this.isLoading = true;
      this.searchChange$.next(depName);
    }
  }
}
