<form
  nz-form
  novalidate
  nzLayout="vertical"
  [nzNoColon]="true"
  [formGroup]="form"
  #f="ngForm"
  (ngSubmit)="onSubmit($event, f)"
  class="application-form">
  <nz-row nzJustify="space-between">
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="name" nzRequired
          >University / Colleague / High School / Class<span class="sws-text-required">*</span></nz-form-label
        >
        <nz-form-control nzErrorTip="University or school name is required.">
          <input sws-input formControlName="name" type="text" nz-input name="name" id="name" placeholder="Enter name of school" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="7">
      <nz-form-item>
        <nz-form-label nzFor="status" nzRequired>Graduated<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Graduation status is required.">
          <nz-radio-group formControlName="status" id="status" nzName="status">
            @for (s of educationStatus; track s) {
              <label [for]="s" sws-radio nz-radio [nzValue]="s">{{ s | titlecase }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="17">
      <nz-form-item>
        <nz-form-label nzFor="qualification" nzRequired
          >Degree / School / Year / Grade<span class="sws-text-required">*</span></nz-form-label
        >
        <nz-form-control nzErrorTip="Education qualification is required.">
          <input
            sws-input
            formControlName="qualification"
            type="text"
            nz-input
            name="qualification"
            id="qualification"
            placeholder="Enter qualification" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="subject">Major Subject</nz-form-label>
        <nz-form-control nzErrorTip="Major subject is required.">
          <input sws-input formControlName="subject" type="text" nz-input name="subject" id="subject" placeholder="Enter major subject" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="years" nzRequired>Years<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Attendance duration is required.">
          <nz-range-picker
            nzId="years"
            nzMode="month"
            [nzShowToday]="false"
            formControlName="duration"
            nzFormat="MMMM yyyy"
            [nzDisabledDate]="disabledDate"
            [nzPlaceHolder]="['From', 'To']" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="remark"
          >Remark&nbsp;<span class="sws-text-muted"> (Optional) </span> <span class="sws-text-required">*</span></nz-form-label
        >
        <nz-form-control>
          <sws-textarea id="remark" formControlName="remark" placeholder="Enter Remark"></sws-textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <sws-button-group>
    <button sws-button swsType="primary" type="submit" nz-button nzType="primary">Submit</button>
  </sws-button-group>
</form>
