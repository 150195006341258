import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouteTitles } from '@router';
import { MergedRouteReducerState } from './merged-route';
import { routerStateKey } from './router.state';

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(routerStateKey);
export const getMergedRoute = createSelector(getRouterReducerState, routerReducerState => routerReducerState?.state);
export const getRouteTitle = createSelector(getMergedRoute, state => state?.title);
export const getCurrentUrl = createSelector(getMergedRoute, state => state?.url);
export const getCurrentUrlParam = createSelector(getMergedRoute, state => {
  return ((state?.url?.includes('update') || state?.url?.includes('detail')) && state?.queryParams['id']) as number | string;
});

export const getCurrentFormID = createSelector(
  getMergedRoute,
  state =>
    (state && (state.url.includes('application-form/update') || state.url.includes('application-form/detail'))
      ? state.queryParams['id']
      : null) as string
);

export const selectApplicationFormRouteTitle = createSelector(getRouteTitle, title =>
  title === RouteTitles.APPLICATION ? title : undefined
);

export const selectApplicationFormUpdateRouteTitle = createSelector(getRouteTitle, title =>
  title === RouteTitles.APPLICATION_UPDATE ? title : undefined
);

export const getSelectedIdFor = (selected: RouteTitles) =>
  createSelector(getRouteTitle, getCurrentUrlParam, (title: string, id: string | number) => (title === selected ? id : undefined));
