import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRole from './role.reducer';
import { getCurrentUrlParam } from '../router/router.selector';
import { IRole } from '@model';

export const selectRoleState = createFeatureSelector<fromRole.IRoleState>(fromRole.ROLE_STATE_KEY);

// get the selectors
const { selectIds, selectEntities, selectAll } = fromRole.roleAdapter.getSelectors();

export const selectRoleIds = createSelector(selectRoleState, selectIds);
export const selectRoleEntities = createSelector(selectRoleState, selectEntities);
export const selectAllRoles = createSelector(selectRoleState, selectAll);
export const selectRoleTotal = createSelector(selectRoleState, state => state.total);

export const getSelectedRole = createSelector(
  selectRoleEntities,
  getCurrentUrlParam,
  (roleEntites, selectedRoleId) => (selectedRoleId && roleEntites[selectedRoleId]) as IRole
);

export const selectPagedRoles = createSelector(selectAllRoles, selectRoleTotal, (roles, total) => ({
  data: [...roles],
  total,
}));

export const selectPageableRole = createSelector(selectRoleState, ({ pageIndex, pageSize, sort, filters }) => ({
  pageIndex,
  pageSize,
  sort,
  filters,
}));
