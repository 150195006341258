import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BaseEntityState, IDepartment, Pageable, Sort } from '@model';
import * as DepartmentActions from './department.action';

export const DEPARTMENT_STATE_KEY = 'department';

export interface IDepartmentState extends Omit<Pageable, 'sort'>, BaseEntityState<IDepartment> {
  sort: Sort | null;
  total: number;
}

const selectDepartmentId = (department: IDepartment): number => department.id!;

export const departmentAdapter: EntityAdapter<IDepartment> = createEntityAdapter<IDepartment>({
  selectId: selectDepartmentId,
});

const initialDepartmentState: IDepartmentState = departmentAdapter.getInitialState({
  pageIndex: 0,
  pageSize: 0,
  sort: null,
  filters: [],
  total: 0,
});

export const departmentReducer = createReducer(
  initialDepartmentState,
  on(DepartmentActions.loadAllDepartments, (state, { pageIndex, pageSize, sort, filters }) => ({
    ...state,
    pageIndex,
    pageSize,
    sort,
    filters,
  })),
  on(DepartmentActions.loadAllDepartmentsSuccess, (state, { data, total }) => departmentAdapter.setAll(data, { ...state, total })),
  on(DepartmentActions.loadAllDepartmentsFailed, state => departmentAdapter.removeAll({ ...state, total: 0 })),
  on(DepartmentActions.addDepartmentSuccess, (state, { data }) => departmentAdapter.addOne(data, { ...state, total: state.total + 1 })),
  on(DepartmentActions.removeDepartmentSuccess, (state, { id }) => departmentAdapter.removeOne(id, { ...state, total: state.total - 1 })),
  on(DepartmentActions.updateDepartmentSuccess, (state, { type, ...data }) => departmentAdapter.updateOne(data, { ...state }))
);
