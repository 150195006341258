<sws-page-subtitle>
  <ng-template sws-title> Applicant's Experience </ng-template>
  <button
    extra
    nz-tooltip
    sws-button
    swsSize="sm"
    type="button"
    (click)="addNewExperience()"
    [disabled]="(formStatus$ | async) !== 'DRAFT'">
    Add
  </button>
</sws-page-subtitle>

<sws-data-table [data]="experienceInfoList" [columns]="columns" swsMode="simple" />

<ng-template let-idx="index" #actionRef>
  <a
    class="sws-btn-danger"
    nz-button
    nzType="link"
    (click)="remove(idx)"
    nz-tooltip
    nzTooltipTitle="Remove"
    [attr.aria-hidden]="true"
    [disabled]="(formStatus$ | async) !== 'DRAFT'">
    <span nz-icon nzType="sws:trash"></span>
  </a>
</ng-template>

<ng-template #durationRef>Period Of Service&nbsp;&nbsp;&#40;From &#8652; To&#41; </ng-template>

<ng-template let-data #durationValueRef>
  {{ data.duration[0] | date: 'dd-MM-yyyy' }}&nbsp; - &nbsp;{{ data.duration[1] | date: 'dd-MM-yyyy' }}
</ng-template>
