import { formatDate } from '@angular/common';
import { TemplateRef } from '@angular/core';
import { NzCheckboxOption } from 'ng-zorro-antd/checkbox';

import { DEFAULT_DATE_PICKER_FORMAT } from '@data';
import { cscPrettyPrint } from '@util';
import { SwsColumnType } from '../datatable';
import { Gender, MaritalStatus } from '../enums';

export enum ApplicationFormStatus {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  APPROVED = 'VERIFIED',
}

export enum ServiceType {
  PART_TIME = 0,
  FULL_TIME = 1,
}
export interface IApplicationInfo {
  id: string;
  appliedDate: Date;
  department: string;
  appliedAddress: string;
  serviceType: ServiceType;
}

export interface IPersonalInfo {
  fullNameRKE: string;
  fullNameEN: string;
  otherName?: string;
  fatherName: string;
  currentCSC: string;
  gender: string;
  sexualOrientation: string;
  currentOccupation: string;
  dateOfBirth: Date;
  age?: number;
  race: string;
  religion: string;
  permanentAddress: string;
  currentAddress: string;
  firstPhoneNumber: string;
  secondPhoneNumber?: string;
  email: string;
  prominentSign?: string;
  height?: number;
  weight?: number;
  skinColor?: string;
  bloodType?: string;
  maritalStatus?: MaritalStatus;
  members?: IFamilyMemberInfo[];
  educationInfo?: IEducationInfo[];
  experienceInfo?: IExperienceInfo[];
}
export interface IAppDocument {
  documentId: number;
  downloadURI: string;
  fileName: string;
  fileType: string;
  previewURI: string;
  questID: string;
  uuid: string;
  data: string;
}
export interface IApplicationForm {
  applicationInfo: IApplicationInfo;
  personalInfo: IPersonalInfo;
  spouseInfo?: IPersonalInfo;
  documents?: IAppDocument[];
  supporters: ISupporterInfo[];
  status?: ApplicationFormStatus;
}

export interface IPagedApplicationForm {
  formID: string;
  appliedDate: Date;
  appliedAddress: string;
  serviceType: boolean;
  departmentName: string;
  applicantNameRKE: string;
  applicantNameEN: string;
  status: ApplicationFormStatus;
}

export interface IExperienceInfo {
  position: string;
  organization: string;
  duration: Date[];
  duty: string;
  remark?: string;
}

export interface IEducationInfo {
  qualification: string;
  name: string;
  subject: string;
  duration: Date[];
  status: string;
  remark?: string;
}

export interface IFamilyMemberInfo {
  fullName: string;
  dateOfBirth: string;
  age: number;
  relationType: string;
  gender: Gender;
  education: string;
  occupation: string;
  address: string;
}

export interface ISupporterInfo {
  id: number;
  name: string;
  occupation: string;
  organization: string;
  isMember: boolean;
  memberSerialNo: string;
  contactNo: string;
  remark?: string;
}

export const applicationTableColumns = (
  statusRef: TemplateRef<IPagedApplicationForm>,
  actionRef: TemplateRef<IPagedApplicationForm>
): Array<SwsColumnType<IPagedApplicationForm>> => {
  return [
    {
      key: 'formID',
      align: { header: 'center', cell: 'center' },
      width: '10%',
      title: 'Form ID',
    },
    {
      key: 'appliedDate',
      width: '10%',
      title: 'Date',
    },
    {
      key: 'applicantNameEN',
      width: '12%',
      title: `Full Name (English)`,
    },
    {
      key: 'currentCSC',
      title: 'NRC / CSC',
      width: '15%',
      value: {
        type: 'function',
        transform: value => cscPrettyPrint(value as string)!,
      },
    },
    {
      key: 'departmentName',
      width: '13%',
      title: `Department`,
    },
    {
      key: 'appliedAddress',
      width: '13%',
      title: `Location`,
    },
    {
      key: 'serviceType',
      width: '10%',
      title: `Service`,
      value: {
        type: 'function',
        transform: value => (value ? 'Full Time' : 'Part Time'),
      },
    },
    {
      key: 'status',
      width: '10%',
      title: 'Status',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: statusRef,
      },
    },
    {
      key: 'action',
      width: '120px',
      title: 'Action',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};

export const formStatusLookUp: NzCheckboxOption[] = [
  {
    label: 'Draft',
    value: ApplicationFormStatus.DRAFT,
  },
  {
    label: 'Completed',
    value: ApplicationFormStatus.COMPLETED,
  },
  {
    label: 'Approved',
    value: ApplicationFormStatus.APPROVED,
  },
];

export const serviceTypeLookup: NzCheckboxOption[] = [
  {
    label: 'Part Time',
    value: '0',
  },
  {
    label: 'Full Time',
    value: '1',
  },
];

export const membersTableColumns = (actionRef: TemplateRef<number>): Array<SwsColumnType<IFamilyMemberInfo>> => {
  return [
    {
      key: 'fullName',
      width: '15%',
      title: 'Name',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'gender',
      width: '10%',
      title: `Gender`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'dateOfBirth',
      width: '16%',
      title: 'Date Of Birth',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'function',
        transform: value => formatDate(value as Date, DEFAULT_DATE_PICKER_FORMAT, 'en'),
      },
    },
    {
      key: 'relationType',
      width: '14%',
      title: `Genealogical Type`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'education',
      width: '20%',
      title: `Education`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'occupation',
      width: '20%',
      title: `Occupation`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'action',
      width: '100px',
      title: 'Action',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};

export const experienceTableColumns = (
  actionRef: TemplateRef<IExperienceInfo>,
  servedYearsRef: TemplateRef<void>,
  durationValueRef: TemplateRef<IExperienceInfo>
): Array<SwsColumnType<IExperienceInfo>> => {
  return [
    {
      key: 'position',
      width: '15%',
      title: 'Position',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'organization',
      width: '25%',
      title: `Organization`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'duration',
      width: '20%',
      title: {
        type: 'template',
        transform: servedYearsRef,
      },
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: durationValueRef,
      },
    },
    {
      key: 'duty',
      width: '35%',
      title: 'Duty / Responsibility',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'action',
      width: '100px',
      title: 'Action',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};

export const qualificationTableColumns = (
  actionRef: TemplateRef<IEducationInfo>,
  {
    title: durationTitle,
    value: durationValue,
  }: {
    title: TemplateRef<unknown>;
    value: TemplateRef<IEducationInfo>;
  },
  statusRef: TemplateRef<IEducationInfo>
): Array<SwsColumnType<IEducationInfo>> => {
  // qualification: string;
  // name: string;
  // subject: string;
  // duration: Date[];
  // status: string;
  // remark?: string;

  return [
    {
      key: 'name',
      width: '25%',
      title: 'School Name',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'qualification',
      width: '20%',
      title: 'Qualification',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'subject',
      width: '20%',
      title: 'Major Subject',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'function',
        transform: val => (val ? val : ' - ') as string,
      },
    },
    {
      key: 'duration',
      width: '20%',
      title: {
        type: 'template',
        transform: durationTitle,
      },
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: durationValue,
      },
    },
    {
      key: 'status',
      width: '10%',
      title: 'Status',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: statusRef,
      },
    },
    {
      key: 'action',
      width: '100px',
      title: 'Action',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};

export const supporterTableColumns = <T extends ISupporterInfo>(actionRef: TemplateRef<T>): Array<SwsColumnType<T>> => {
  return [
    {
      key: 'name',
      width: '15%',
      title: 'Full Name',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'occupation',
      width: '20%',
      title: `Position / Occupation`,
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'organization',
      width: '20%',
      title: 'Organization',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'isMember',
      width: '12%',
      title: 'Party Member',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'function',
        transform: value => ((value as boolean) ? 'Yes' : 'No'),
      },
    },
    {
      key: 'memberSerialNo',
      width: '13%',
      title: 'Serial No',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'contactNo',
      width: '15%',
      title: 'Contact No',
      align: { header: 'center', cell: 'center' },
    },
    {
      key: 'action',
      width: '100px',
      title: 'Action',
      align: { header: 'center', cell: 'center' },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};
