import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import * as DocumentQuestActions from './docs-quest.action';
import { routerNavigatedAction } from '@ngrx/router-store';
import { DocumentQuestsService } from '@services';
import { Router } from '@angular/router';

@Injectable()
export class DocumentQuestionEffects {
  constructor(
    private actions$: Actions,
    private documentService: DocumentQuestsService,
    private router: Router
  ) {}

  onRouteApplicationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(
        ({
          payload: {
            routerState: { url },
          },
        }) => {
          return url.includes('application-form/create') || url.includes('application-form/update');
        }
      ),
      map(() => DocumentQuestActions.loadAllDocsQuest())
    )
  );

  loadPageableDocsQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentQuestActions.loadPageableDocsQuest),
      mergeMap(({ pageIndex, pageSize, sort, filters }) => {
        return this.documentService.loadPageableDocQuest({ pageIndex, pageSize, sort, filters }).pipe(
          map(({ data, total }) => DocumentQuestActions.loadPageableDocsQuestSuccess({ data: [...data], total })),
          catchError(() => of(DocumentQuestActions.loadPageableDocsQuestFailed()))
        );
      })
    )
  );

  loadAllDocsQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentQuestActions.loadAllDocsQuest),
      mergeMap(() => {
        return this.documentService.loadAllDocQuest().pipe(
          map(data => DocumentQuestActions.loadAllDocsQuestSuccess({ data: [...data] })),
          catchError(() => of(DocumentQuestActions.loadAllDocsQuestFailed()))
        );
      })
    )
  );

  // updateDocQuests$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DocumentQuestActions.updateDocsQuest),
  //     mergeMap(({ type, ...data }) => {
  //       return this.documentService.updateDocumentQuest({ ...data }).pipe(
  //         map(() =>
  //           DocumentQuestActions.updateDocsQuestSuccess({
  //             data: Object.entries(data).map<IQuestion>(([k, v]) => ({ id: +k, title: v })),
  //           })
  //         ),
  //         catchError(() => of(DocumentQuestActions.updateDocsQuestFailed()))
  //       );
  //     })
  //   )
  // );

  // onAddDocQuest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DocumentQuestActions.addDocsQuest),
  //     mergeMap(({ type, ...data }) =>
  //       this.documentService.create(data).pipe(
  //         map((createdRole) => {
  //           this.router.navigateByUrl('/role');
  //           return RoleActions.addRoleSuccess({ data: createdRole });
  //         }),
  //         catchError(() => of(RoleActions.addRoleFailed()))
  //       )
  //     )
  //   )
  // );

  updateDocQuest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentQuestActions.updateDocsQuest),
      mergeMap(({ type, ...data }) =>
        this.documentService.updateOne(data.id, data).pipe(
          map(({ ...data }) => {
            this.router.navigateByUrl('/setting/document');
            return DocumentQuestActions.updateDocsQuestSuccess({
              changes: {
                ...data,
              },
              id: data.id!,
            });
          }),
          catchError(() => of(DocumentQuestActions.updateDocsQuestFailed()))
        )
      )
    )
  );
}
