import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ApplicationFormStatus,
  DataTable,
  IApiResponse,
  IApplicationForm,
  IApplicationInfo,
  IPagedApplicationForm,
  Pageable,
} from '@model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFormService {
  constructor(
    private http: HttpClient,
    private builder: FormBuilder
  ) {}

  loadApplicationForms(pageable: Pageable) {
    return this.http.post<DataTable<IPagedApplicationForm>>(`/application-form/all`, { ...pageable });
  }

  findOne(id: string) {
    return this.http.get<IApplicationForm>(`/application-form/${id}`);
  }

  create(data: IApplicationForm) {
    return this.http.post<IApiResponse<IApplicationInfo>>('/application-form/create', { ...data });
  }

  update(id: string, data: IApplicationForm) {
    return this.http.post<IApiResponse<IApplicationInfo>>(`/application-form/update/${id}`, { ...data });
  }

  checkForm(id: string): Observable<IApiResponse<ApplicationFormStatus>> {
    return this.http.get<IApiResponse<ApplicationFormStatus>>(`/application-form/${id}/check`);
  }

  createMemberForm(): FormGroup {
    return this.builder.group({
      id: [''],
      fullName: ['', Validators.required],
      dateOfBirth: [null, Validators.required],
      relationType: ['', Validators.required],
      gender: [null, Validators.required],
      education: ['', Validators.required],
      occupation: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  createExperienceForm(): FormGroup {
    return this.builder.group({
      id: [''],
      position: ['', Validators.required],
      organization: ['', Validators.required],
      duration: [null, Validators.required],
      duty: ['', Validators.required],
      remark: [''],
    });
  }

  createEducationForm(): FormGroup {
    return this.builder.group({
      id: [''],
      qualification: ['', Validators.required],
      name: ['', Validators.required],
      subject: [''],
      duration: [[], Validators.required],
      status: ['', Validators.required],
      remark: [''],
    });
  }

  createSupporterForm(): FormGroup {
    return this.builder.group({
      id: [''],
      name: ['', [Validators.required]],
      occupation: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      isMember: [null, [Validators.required]],
      memberSerialNo: [''],
      contactNo: ['', [Validators.required]],
      remark: [''],
    });
  }

  createPersonalInfoForm(spouse = false): FormGroup {
    const personalForm: FormGroup = this.builder.group({
      id: [''],
      fullNameRKE: ['', Validators.required],
      fullNameEN: ['', Validators.required],
      fatherName: ['', Validators.required],
      currentCSC: [null, Validators.required],
      gender: [null, Validators.required],
      genderOrientation: [null, Validators.required],
      occupation: ['', Validators.required],
      dateOfBirth: [null, Validators.required],
      race: ['', Validators.required],
      religion: ['', Validators.required],
      permanentAddress: ['', Validators.required],
      currentAddress: ['', Validators.required],
      firstPhoneNumber: ['', Validators.required],
      secondPhoneNumber: [''],
      email: ['', [Validators.required, Validators.email]], // TODO: need to confirm,
      members: this.builder.array([]),
    });
    if (!spouse) {
      personalForm.addControl('otherName', new FormControl(''));
      personalForm.addControl('prominentSign', new FormControl('', [Validators.required]));
      personalForm.addControl('height', new FormControl(null, [Validators.required]));
      personalForm.addControl('weight', new FormControl(null, [Validators.required, Validators.pattern('^[0-9]*$')]));
      personalForm.addControl('skinColor', new FormControl('', [Validators.required]));
      personalForm.addControl('bloodType', new FormControl(null, [Validators.required]));
      personalForm.addControl('maritalStatus', new FormControl(null, [Validators.required]));
      // personalForm.addControl('educationInfo', this.builder.array([]));
      // personalForm.addControl('experienceInfo', this.builder.array([]));
    }
    return personalForm;
  }
}
