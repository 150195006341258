import { EntityState } from '@ngrx/entity';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

import { ThemeType } from '@ant-design/icons-angular';
import { RouteTitles, RouteURI } from '@router';
import { range as _range } from 'lodash';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { Gender } from '../enums';

export type Language = 'en' | 'rke';
export const LANGUAGES: NzSelectOptionInterface[] = [
  { label: 'English', value: 'en' },
  { label: 'Rakhine', value: 'rke' },
];
export const DEFAULT_HEIGHT_OPTIONS: NzCascaderOption[] = _range(1, 10).map<NzCascaderOption>(val => ({
  label: val + ' ft',
  value: val,
  children: _range(0, 12).map<NzCascaderOption>(val => ({ label: val + ' in', value: val, isLeaf: true })),
}));

export interface ISetting {
  id: number;
  configType: string;
  configKey: string;
  value1: string;
  value2: string | null;
  value3: string | null;
  description: string | null;
}

export interface I18nPair {
  en: string;
  mm: string;
}
export interface IDivision {
  id: number;
  iso_code: string;
  code: I18nPair;
  fullName: I18nPair;
  shortName: I18nPair;
  otherName: I18nPair;
  divisionType: I18nPair;
  cities: ICscCity[];
}

export interface ICscCity {
  id: number;
  code: string;
  shortMM: string;
  shortEN: string;
  nameMM: string;
  nameEN: string;
}

export interface IApiResponse<T> {
  data: T;
}

export interface GenderType {
  id?: number;
  label: string;
  value: Gender;
}

export interface BaseEntityState<T> extends EntityState<T> {
  total: number;
}

export type OnChangeFn<T> = (value: T) => void;
export type OnTouchedFn = () => void;

// နိုင်ငံသား (နိုင်)	Citizen (C)
// ဧည့်နိုင်ငံသား (ဧည့်)	Associate Citizen (AC)
// နိုင်ငံသားပြုခွင့်ရသူ (ပြု)	Naturalized Citizen (NC)
// နိုင်ငံသားပြုစီစစ်ခံရမည့်သူ (စ)	National Verification (V)
// သာသနာဝင် (သ)	Monk (M)
// သာသနာနွယ်ဝင် (သီ)	Nun (N)

export const NRC_TYPES = [
  { name: { en: 'C', mm: 'နိုင်' } },
  { name: { en: 'AC', mm: 'ဧည့်' } },
  { name: { en: 'NC', mm: 'ပြု' } },
  { name: { en: 'V', mm: 'စ' } },
  { name: { en: 'M', mm: 'သ' } },
  { name: { en: 'N', mm: 'သီ' } },
];

export const NRC_INFO = NRC_TYPES.reduce(
  (accumulator, current, idx) => {
    const { name } = current;
    const tempEn = { key: idx + 1, value: name['en'] };
    const tempMM = { key: idx + 1, value: name['mm'] };
    if (accumulator['en']) {
      accumulator['en'].push(tempEn);
    } else {
      accumulator['en'] = [tempEn];
    }
    if (accumulator['mm']) {
      accumulator['mm'].push(tempMM);
    } else {
      accumulator['mm'] = [tempMM];
    }
    return { ...accumulator, mm: [...(accumulator['mm'] || [])], en: [...(accumulator['en'] || [])] };
  },
  {} as Record<'en' | 'mm', Array<Record<'key' | 'value', number | string>>>
);

export enum MSGCode {
  MSG_403 = '403',
  MSG_00 = '00',
  MSG_01 = '01',
  MSG_02 = '02',
  MSG_03 = '03',
  MSG_04 = '04',
  MSG_05 = '05',
  MSG_06 = '06',
  MSG_07 = '07',
  MSG_09 = '09',
}

export const ERROR_MESSAGES: Record<MSGCode, string> = {
  [MSGCode.MSG_403]: 'FORBIDDEN',
  [MSGCode.MSG_00]: 'ERROR',
  [MSGCode.MSG_01]: `UNAUTHORIZED`,
  [MSGCode.MSG_02]: 'FORBIDDEN',
  [MSGCode.MSG_03]: 'ACCOUNT STATUS',
  [MSGCode.MSG_04]: 'ACCOUNT STATUS',
  [MSGCode.MSG_05]: 'ACCOUNT STATUS',
  [MSGCode.MSG_06]: 'ACCOUNT STATUS',
  [MSGCode.MSG_07]: 'ACCOUNT STATUS',
  [MSGCode.MSG_09]: 'ACCESS DENIED',
};

export type valueof<T> = T[keyof T];
type SWSMenuItemType = { title: string; icon: { type: string; theme: ThemeType }; uri: string };
type SWSSubMenuItemType = SWSMenuItemType & { submenu: SWSMenuItemType[] };
export type SWSMenuItem = SWSMenuItemType | SWSSubMenuItemType | any;
export type SWSMenu = Record<string, SWSMenuItem>;
const MENU_KEYS = {
  [RouteTitles.APPLICATION]: 0,
  [RouteTitles.DEPARTMENT]: 1,
  [RouteTitles.USER]: 2,
  [RouteTitles.ROLE]: 3,
  [RouteTitles.SETTING]: 4,
};
export const menuSortFn = (s1: string, s2: string) => MENU_KEYS[s1 as keyof typeof MENU_KEYS] - MENU_KEYS[s2 as keyof typeof MENU_KEYS];
export const HOME_MENU: SWSMenu = {
  [RouteTitles.APPLICATION]: {
    title: 'Application Form',
    icon: {
      type: 'sws:file-text',
      theme: 'outline',
    },
    uri: RouteURI.APPLICATION_FORM,
  },
  [RouteTitles.DEPARTMENT]: {
    title: 'Department',
    icon: {
      type: 'sws:diagram',
      theme: 'fill',
    },
    uri: RouteURI.DEPARTMENT,
  },
  [RouteTitles.ROLE]: {
    title: 'Role',
    icon: {
      type: 'sws:layers',
      theme: 'outline',
    },
    uri: RouteURI.ROLE,
  },
  [RouteTitles.USER]: {
    title: 'User',
    icon: {
      type: 'sws:people',
      theme: 'outline',
    },
    uri: RouteURI.USER,
  },
  [RouteTitles.SETTING]: {
    title: 'Setting',
    icon: {
      type: 'sws:gear',
      theme: 'fill',
    },
    uri: RouteURI.SETTING,
    submenu: [
      {
        title: 'Doc Questions',
        icon: {
          type: 'file-protect',
          theme: 'outline',
        },
        uri: RouteURI.DOCUMENT,
      },
    ],
  },
};
