import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'sws-image-preview',
  templateUrl: './sws-image-preview.component.html',
  styleUrl: './sws-image-preview.component.scss',
  standalone: false,
})
export class SwsImagePreviewComponent {
  nzData: { previewImage: string } = inject(NZ_MODAL_DATA);
}
