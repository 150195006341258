<nz-form-label [nzFor]="swsId()" [nzRequired]="required" [nzSpan]="swsSpan()">
  <ng-content></ng-content>
  @if (required) {
    <span class="sws-text-required">*</span>
  }
  <span
    nz-tooltip
    [nzTooltipTitle]="cscTpl"
    class="sws-text-info"
    nz-icon
    nzType="sws:info-circle"
    nzTheme="fill"
    nzTooltipOverlayClassName="sws-tooltip-overlay"></span>
</nz-form-label>
<nz-form-control>
  <nz-input-group class="sws-input-group" [nzStatus]="hasError ? 'error' : ''" [ngClass]="{ 'sws-status-error': hasError }" [id]="swsId()">
    <nz-cascader
      [nzPlaceHolder]="cscTypePlaceholder"
      [nzAllowClear]="false"
      [nzLabelRender]="cscRender"
      [nzOptions]="cscOptions"
      [nzSuffixIcon]="'caret-down'"
      [nzStatus]="hasError ? 'error' : ''"
      [(ngModel)]="cscType"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onCscTypeChange($event)"
      (nzClear)="onClearCscType()"
      [nzDisabled]="disabled">
      <ng-template #cscRender let-labels="labels" let-selectedOptions="selectedOptions">
        @if (labels.length > 0) {
          <span>{{ prettyPrint(labels) }}</span>
        }
      </ng-template>
    </nz-cascader>
    <input
      type="text"
      nz-input
      sws-number-only
      [max]="6"
      [placeholder]="cscNumberPlaceholder"
      [(ngModel)]="cscNumber"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onCscNumberChange($event)"
      [nzStatus]="hasError ? 'error' : ''"
      [disabled]="disabled" />
  </nz-input-group>
  @if (hasError) {
    <div class="ant-form-item-explain ant-form-item-explain-connected">
      <div role="alert" class="ant-form-item-explain-error">
        @if ((currentCsc.hasError('required') || currentCsc.hasError('invalidNumber')) && currentCsc.hasError('invalidType')) {
          Valid NRC/CSC is required.
        } @else if (currentCsc.hasError('required')) {
          NRC/CSC number is required.
        } @else if (currentCsc.hasError('invalidNumber')) {
          Please enter valid NRC/CSC.
        } @else if (currentCsc.hasError('invalidType')) {
          Please select NRC/CSC type.
        }
      </div>
    </div>
  }
</nz-form-control>
<ng-template #cscTpl>
  <div class="sws-csc-info-row">
    @for (mm of nrcMMTypes; track mm.key) {
      <span class="sws-csc-info-item">({{ mm.value }})</span>
      @if ($index !== nrcMMTypes.length - 1) {
        <span>&verbar;</span>
      }
    }
  </div>
  <div class="sws-csc-info-row">
    @for (en of nrcENTypes; track en.key) {
      <span class="sws-csc-info-item">({{ en.value }})</span>
      @if ($index !== nrcENTypes.length - 1) {
        <span>&verbar;</span>
      }
    }
  </div>
  <div class="sws-info-hint">{{ 'NRC number only allowed (0 - 9)' }} or {{ ' (\u1040 - \u1049)' | mmDigit }}</div>
</ng-template>
