import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTable, IUser, Pageable } from '@model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`/user/all`);
  }

  loadUsers(pageable: Pageable): Observable<DataTable<IUser>> {
    return this.http.post<DataTable<IUser>>(`/user/all`, { ...pageable });
  }

  create(user: IUser): Observable<IUser> {
    return this.http.post<IUser>('/user/create', { ...user });
  }

  deleteOne(id: number): Observable<void> {
    return this.http.delete<void>(`/user/${id}/remove`);
  }

  updateOne(id: number, user: IUser): Observable<IUser> {
    return this.http.put<IUser>(`/user/${id}/update`, { ...user, roles: user.roles?.map(r => ({ id: r.id })) });
  }
}
