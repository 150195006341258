<ng-container [formGroup]="form">
  <nz-tabset nzTabPosition="top" nzType="card" [nzAnimated]="false">
    <nz-tab nzTitle="Applicant Information" [nzForceRender]="false">
      <!-- Applicant Info -->
      <sws-personal-info (maritalStatusChange)="onMaritalStatusChange($event)"></sws-personal-info>
      <!-- Spouse Info -->
      @if (applicantMaritalStatus === 'MARRIED') {
        <nz-divider />
        <sws-spouse-info formGroupName="spouseInfo"></sws-spouse-info>
      }
    </nz-tab>
    <nz-tab nzTitle="Family Members" [nzForceRender]="false">
      <!-- Family Members -->
      <sws-family-members formArrayName="members">
        <ng-template sws-title>Applicant's Family Members</ng-template>
      </sws-family-members>
      @if (applicantMaritalStatus === 'MARRIED') {
        <nz-divider class="sws-my-2" />
        <sws-family-members [parent]="spouseForm" formArrayName="members">
          <ng-template sws-title>Spouse's Family Members</ng-template>
        </sws-family-members>
      }
    </nz-tab>
  </nz-tabset>
</ng-container>
