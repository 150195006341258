import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[sws-select]',
  standalone: false,
})
export class SwsSelectDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    this.elementRef.nativeElement.classList.add('sws-select');
  }
}
