import { HOME_MENU, menuSortFn, SWSMenuItem, UserStatus } from '@model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.IAuthState>(fromAuth.AUTH_STATE_KEY);

export const isAuthenticated = createSelector(selectAuthState, state => !!state.token && state.status !== UserStatus.EXPIRED);

export const selectUsername = (en: string) =>
  createSelector(selectAuthState, state => (en == 'en' || en == 'en-US' ? state.fullNameEN! : state.fullNameRKE!));

export const selectLoginID = createSelector(selectAuthState, state => state.userId!);

export const selectCurrentAuthRole = createSelector(selectAuthState, state => state.currentRole!);

export const selectAuthUserRoles = createSelector(selectAuthState, state => state.roles!);

export const selectAuthorizedScreens = createSelector(selectAuthState, selectCurrentAuthRole, (state, currentRole) => {
  const idx = state.roles?.findIndex(role => role.code === currentRole.code)!;
  if (idx > -1) {
    return state.roles![idx].screens;
  }
  return [];
});

export const isAuthorizedScreen = (screen: string) =>
  createSelector(selectAuthorizedScreens, screens => screens.findIndex(s => s === screen) > -1);

export const selectAuthorizedMenus = createSelector(
  selectAuthorizedScreens,
  screens => [...screens].sort(menuSortFn).map(screen => _cloneDeep(HOME_MENU)[screen]) as Array<SWSMenuItem>
);
