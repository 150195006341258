import { TemplateRef } from '@angular/core';
import { SwsColumnType } from '../datatable';

export interface IQuestion {
  id: number;
  titleEN: string;
  titleRKE: string;
  maxUploadLimit: number;
  description?: string;
}

export const questionTableColumns = (actionRef: TemplateRef<IQuestion>): Array<SwsColumnType<IQuestion>> => {
  return [
    {
      key: 'id',
      align: { header: 'center', cell: 'center' },
      width: '8%',
      title: 'ID',
    },
    {
      key: 'titleEN',
      align: { header: 'center', cell: 'center' },
      width: '40%',
      title: 'Question',
    },
    {
      key: 'maxUploadLimit',
      width: '12%',
      title: 'Upload Limit',
    },
    {
      key: 'description',
      // width: '20%',
      title: 'Question Description',
      align: {
        header: 'center',
        cell: 'center',
      },
    },
    {
      key: 'action',
      width: '120px',
      title: 'Action',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};
