<nz-form-label [nzFor]="id" [nzRequired]="required">
  <ng-content></ng-content>
  @if(required) {
  <span class="sws-text-required">*</span>
  }
  <span
    nz-tooltip
    [nzTooltipTitle]="phoneNumberTpl"
    class="sws-text-info"
    nz-icon
    nzType="sws:info-circle"
    nzTheme="fill"
    nzTooltipOverlayClassName="sws-tooltip-overlay"
  ></span>
</nz-form-label>
<nz-form-control>
  <nz-input-group
    class="sws-input-group"
    [nzStatus]="phoneNumber.errors && phoneNumber.dirty ? 'error' : ''"
    [ngClass]="{ 'sws-status-error': phoneNumber.errors && phoneNumber.dirty }"
  >
    <nz-select
      [nzAllowClear]="false"
      [nzAutoClearSearchValue]="true"
      [nzShowSearch]="true"
      [(ngModel)]="selectedCountry"
      [ngModelOptions]="{ standalone: true }"
      [nzCustomTemplate]="defaultTemplate"
      (ngModelChange)="onCountrySelect()"
      [nzDisabled]="disabled"
    >
      @for (item of countries; track $index) {
      <nz-option
        [nzLabel]="item.code + '|' + item.dial_code"
        [nzValue]="item.dial_code"
        nzCustomContent
        [nzKey]="item.code + item.dial_code"
      >
        <div class="sws-option-wrapper">
          <img [src]="'/assets/flags/' + item.code.toLowerCase() + '.svg'" [alt]="item.name" class="sws-flag-icon" />
          <span class="sws-option-selected"> {{ item.dial_code }} </span>
        </div>
      </nz-option>
      }
    </nz-select>
    <input
      type="text"
      nz-input
      sws-number-only
      [id]="id"
      [leadingZero]="false"
      [(ngModel)]="numberInput"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onNumberInputChange()"
      [placeholder]="placeholder()"
      [disabled]="disabled"
    />
  </nz-input-group>
  @if (phoneNumber.errors && phoneNumber.dirty) {
  <div class="ant-form-item-explain ant-form-item-explain-connected">
    <div role="alert" class="ant-form-item-explain-error">
      @if(phoneNumber.hasError('required')) { {{ swsRequired() }} } @else if (phoneNumber.hasError('invalidNumber')) { Phone enter valid
      phone number. }
    </div>
  </div>
  }
</nz-form-control>

<ng-template #defaultTemplate let-selected>
  <div class="sws-option-wrapper">
    <img [src]="'/assets/flags/' + selected.nzLabel.split('|')[0].toLowerCase() + '.svg'" [alt]="selected.nzLabel" class="sws-flag-icon" />
    <span class="sws-option-selected">{{ selected.nzLabel.split('|')[1] }}</span>
  </div>
</ng-template>

<ng-template #phoneNumberTpl>
  <div class="sws-info-hint">Leading zero (0) value not required.</div>
  <div class="sws-info-hint">e.g (0912345678) same as (912345678)</div>
</ng-template>
