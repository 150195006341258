import { AuthResponse, IUser, UpdatePassword } from '@model';
import { createAction, props } from '@ngrx/store';

export const login = createAction('[auth] Login', props<{ username: string; password: string }>());

export const loginSuccess = createAction('[auth] Login Success', props<AuthResponse>());

export const loginFailed = createAction('[auth] Login Failed', props<{ message: string }>());

export const logout = createAction('[auth] Logout');

export const logoutSuccess = createAction('[auth] Logout Success');

export const logoutFailed = createAction('[auth] Logout Failed');

export const clearAuthState = createAction('[auth] Clear');

export const getPrincipal = createAction('[auth] Principal');

export const getPrincipalSuccess = createAction('[auth] Principal', props<IUser>());

export const changePassword = createAction('[auth] Update Gnerate Password', props<{ data: UpdatePassword }>());

export const changePasswordSuccess = createAction('[auth] Update Generate Password Success', props<{ message: string }>());

export const changePasswordFailed = createAction('[auth] Update Generate Password Failed', props<{ message: string }>());

export const resetPassword = createAction('[user] Reset Password', props<{ loginId: string }>());
