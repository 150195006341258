<ng-container [formGroupName]="formGroupName">
  <nz-row nzJustify="space-between">
    <nz-col nzFlex="100%">
      <sws-page-subtitle>
        <ng-template sws-title>Application Information</ng-template>
      </sws-page-subtitle>
    </nz-col>
  </nz-row>
  <nz-row nzJustify="space-between">
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="appliedDate" nzRequired
          >{{ 'application.form.applicationInfo.appliedDate' | translate }} <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Applied date is required.">
          <nz-date-picker
            nzId="appliedDate"
            formControlName="appliedDate"
            nzPlaceHolder="Choose applied date"
            [nzAllowClear]="false"
            nzFormat="dd-MM-yyyy"
            [nzDisabledDate]="disabledDate" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"> </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="department" nzRequired
          >{{ 'application.form.applicationInfo.department' | translate }} <span class="sws-text-required">*</span></nz-form-label
        >
        <nz-form-control nzErrorTip="Deparment is required.">
          <nz-select
            formControlName="department"
            sws-select
            name="department"
            nzId="department"
            nzPlaceHolder="Choose department"
            [nzShowSearch]="true"
            [nzAllowClear]="false"
            [nzServerSearch]="true"
            (nzOnSearch)="onSearchDepartment($event)"
            (nzOpenChange)="onSearchDepartment('')">
            @for (department of departments$ | async; track department.id) {
              @if (!isLoading) {
                <nz-option [nzValue]="department.id" [nzLabel]="department.nameEN"></nz-option>
              }
            }
            @if (isLoading) {
              <nz-option nzDisabled nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Loading Data...
              </nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"> </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="appliedAddress" nzRequired>
          {{ 'application.form.applicationInfo.appliedAddress' | translate }}
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Location is required.">
          <input
            type="text"
            id="appliedAddress"
            name="appliedAddress"
            sws-input
            nz-input
            formControlName="appliedAddress"
            placeholder="Enter location or region" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="serviceType" nzRequired>
          {{ 'application.form.applicationInfo.serviceType' | translate }}
          <span class="sws-text-required">*</span>
        </nz-form-label>
        <nz-form-control nzErrorTip="Service type is required.">
          <nz-radio-group id="serviceType" formControlName="serviceType">
            <label for="full time" sws-radio nz-radio [nzValue]="1">Full Time</label>
            <label for="part time" sws-radio nz-radio [nzValue]="0">Part Time</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>
  </nz-row>
</ng-container>
