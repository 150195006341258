<sws-page-subtitle>
  <ng-template sws-title>
    <ng-container [ngTemplateOutlet]="titleContent()?.title!"></ng-container>
  </ng-template>
  <button extra nz-tooltip sws-button swsSize="sm" type="button" (click)="addChildren()" [disabled]="(formStatus$ | async) !== 'DRAFT'">Add</button>
</sws-page-subtitle>

<sws-data-table [data]="members" [columns]="columns" swsMode="simple" />

<ng-template let-idx="index" #actionRef>
  <a class="sws-btn-danger" nz-button nzType="link" (click)="remove(idx)" nz-tooltip nzTooltipTitle="Remove" [disabled]="form.disabled">
    <span nz-icon nzType="sws:trash"></span>
  </a>
</ng-template>
