import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as StoreActions from '@store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class ApplicationEffects {
  constructor(
    private actions$: Actions,
    private messageService: NzMessageService
  ) {}

  onSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StoreActions.addUserSuccess.type,
          StoreActions.removeUserSuccess.type,
          StoreActions.updateUserSuccess.type,
          StoreActions.addRoleSuccess.type,
          StoreActions.removeRoleSuccess.type,
          StoreActions.updateRoleSuccess.type,
          StoreActions.addDepartmentSuccess.type,
          StoreActions.removeDepartmentSuccess.type,
          StoreActions.updateDepartmentSuccess.type
        ),
        tap(({ type }) => {
          switch (type) {
            case StoreActions.addUserSuccess.type:
            case StoreActions.addRoleSuccess.type:
            case StoreActions.addDepartmentSuccess.type:
              this.messageService.success('Operation Successful');
              // this.messageService.success(`Added new ${type.match(/\[(.*?)\]/)![1]}`);
              break;
            case StoreActions.removeUserSuccess.type:
            case StoreActions.removeRoleSuccess.type:
            case StoreActions.removeDepartmentSuccess.type:
              this.messageService.success('Operation Successful');
              // this.messageService.success(`${val.charAt(0).toUpperCase() + val.slice(1)} removed successful`);
              break;
            case StoreActions.updateUserSuccess.type:
            case StoreActions.updateRoleSuccess.type:
            case StoreActions.updateDepartmentSuccess.type:
              this.messageService.success('Operation Successful');
              // this.messageService.success(`${val.charAt(0).toUpperCase() + val.slice(1)} updated successful`);
              break;
          }
        })
      ),
    { dispatch: false }
  );

  onFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StoreActions.addUserFailed,
          StoreActions.removeUserFailed,
          StoreActions.updateUserFailed,
          StoreActions.addRoleFailed,
          StoreActions.removeRoleFailed,
          StoreActions.updateRoleFailed,
          StoreActions.addDepartmentFailed,
          StoreActions.removeDepartmentFailed,
          StoreActions.updateDepartmentFailed
        ),
        filter(action => !!(action as { message: string })['message']),
        tap(action => {
          const { type } = action;
          const message = (action as { message: string })['message'];
          switch (type) {
            case StoreActions.addUserFailed.type:
            case StoreActions.addRoleFailed.type:
            case StoreActions.addDepartmentFailed.type:
              this.messageService.error(`Operation Failed ${message ? message : ''}`);
              // this.messageService.error(`Unable to add new ${type.match(/\[(.*?)\]/)![1]}`);
              break;
            case StoreActions.removeUserFailed.type:
            case StoreActions.removeRoleFailed.type:
            case StoreActions.removeDepartmentFailed.type:
              this.messageService.error('Operation Failed');
              // this.messageService.error(`Unable to remove ${type.match(/\[(.*?)\]/)![1]}`);
              break;
            case StoreActions.updateUserFailed.type:
            case StoreActions.updateRoleFailed.type:
            case StoreActions.updateDepartmentFailed.type:
              this.messageService.error('Operation Failed');
              // this.messageService.error(`Unable to update ${type.match(/\[(.*?)\]/)![1]}`);
              break;
          }
        })
      ),
    { dispatch: false }
  );
}
