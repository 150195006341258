import { Attribute, Component, forwardRef, inject, input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DEFAULT_DATE_PICKER_FORMAT } from '@data';
import { OnChangeFn, OnTouchedFn } from '@model';
import { CommonService } from '@services';

@Component({
  selector: 'sws-input-dob',
  templateUrl: './sws-input-dob.component.html',
  styleUrl: './sws-input-dob.component.scss',
  standalone: false,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwsDobInputComponent),
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SwsDobInputComponent implements OnInit, ControlValueAccessor {
  _onChange!: OnChangeFn<string>;
  _onTouched!: OnTouchedFn;
  disabled = false;
  required = false;
  swsFormat = input<string>(DEFAULT_DATE_PICKER_FORMAT);
  constructor(
    @Attribute('formControlName') readonly formControlName: string,
    private readonly rootForm: FormGroupDirective,
    private readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.required = this.control.hasValidator(Validators.required);
  }

  writeValue(_value: string): void {}

  registerOnChange(fn: OnChangeFn<string>): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: OnTouchedFn): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  disabledDate = this.commonService.disabledDate;

  get control() {
    return this.rootForm.control.get(this.formControlName) as FormControl;
  }
}
