import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { COMMON_COMPONENTS } from '@components';
import SWS_DIRECTIVES, { SwsNumberOnlyDirective } from '@directives';
import { TranslateModule } from '@ngx-translate/core';
import SWS_PIPES from '@pipes';
import { NgZorroAntDModule } from './ng-zorro-antd/ng-zorro-antd.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgZorroAntDModule],
  declarations: [SWS_PIPES, SWS_DIRECTIVES, ...COMMON_COMPONENTS],
  exports: [
    SWS_PIPES,
    SWS_DIRECTIVES,
    COMMON_COMPONENTS,
    NgZorroAntDModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
  ],
  providers: [
    SWS_PIPES,
    DatePipe,
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SwsNumberOnlyDirective,
    },
    TranslateModule,
  ],
})
export class SharedModule {}
