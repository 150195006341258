import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@services';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { exhaustMap } from 'rxjs/internal/operators/exhaustMap';
import { map } from 'rxjs/internal/operators/map';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { tap } from 'rxjs/internal/operators/tap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';

import { MSGCode, UserStatus } from '@model';
import { RouteURI } from '@router';
import * as AuthActions from './auth.action';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly nzMessageService: NzMessageService,
    private readonly route: ActivatedRoute
  ) {}

  onLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ username, password }) =>
        this.authService.login(username, password).pipe(
          map(({ data: { token, user } }) => {
            return AuthActions.loginSuccess({ token, user: { ...user } });
          }),
          catchError((err: HttpErrorResponse) =>
            of(
              AuthActions.loginFailed({
                message: err?.error?.message || err?.error?.errors[0]?.message || MSGCode.MSG_00,
              })
            )
          )
        )
      )
    )
  );

  onLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        withLatestFrom(this.route.queryParamMap),
        tap(([{ user }, paramMap]) => {
          let returnUrl;
          if (paramMap.has('returnUrl')) {
            returnUrl = paramMap.get('returnUrl')!;
          }
          if (user.status == UserStatus.PENDING) {
            this.router.navigate([`/change-password`], { replaceUrl: true, queryParams: { returnUrl } });
            return;
          }
          this.router.navigateByUrl(returnUrl || '/application-form', { replaceUrl: true });
        })
      ),
    { dispatch: false }
  );

  onLogOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(() => {
        return this.authService.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError(() => of(AuthActions.logoutFailed()))
        );
      })
    )
  );

  onCheckPrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getPrincipal),
      exhaustMap(() =>
        this.authService.getPrincipal().pipe(
          map(data => {
            return AuthActions.getPrincipalSuccess({ ...data });
          }),
          catchError(() => {
            return EMPTY;
          })
        )
      )
    )
  );

  onChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      exhaustMap(({ data }) =>
        this.authService.updateGeneratedPass(data).pipe(
          map(({ data }) => AuthActions.changePasswordSuccess({ message: data })),
          catchError(({ error }: HttpErrorResponse) => {
            const { message } = error;
            return of(AuthActions.changePasswordFailed({ message }));
          })
        )
      )
    )
  );

  onUpdatePassSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePasswordSuccess),
      tap(({ message }) => {
        this.nzMessageService.success(`${message}`);
      }),
      map(() => AuthActions.logout())
    )
  );

  onUpdatePassFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.changePasswordFailed),
        tap(({ message }) => this.nzMessageService.error(`${message}`))
      ),
    { dispatch: false }
  );

  onReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resetPassword),
        mergeMap(({ loginId }) =>
          this.authService.reset(loginId).pipe(
            map(({ data }) => {
              this.nzMessageService.success(`${data}`);
              return this.router.navigate([RouteURI.USER]);
            }),
            catchError(err => {
              const message = err.error.message;
              this.nzMessageService.error(`${message}`);
              return throwError(() => err);
            })
          )
        )
      ),
    { dispatch: false }
  );
}
