import { IUser, Pageable } from '@model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

// load
export const loadAllUsers = createAction('[user] Load All Users', props<Pageable>());
export const loadAllUsersSuccess = createAction('[user] Load All Users Success', props<{ users: IUser[]; total: number }>());
export const loadAllUsersFailed = createAction('[user] Load All Users Failed');

// create
export const addUser = createAction('[user] Add New User', props<IUser>());
export const addUserSuccess = createAction('[user] Add New User Success', props<IUser>());
export const addUserFailed = createAction('[user] Add New User Failed', props<{ message: string }>());

// remove
export const removeUser = createAction('[user] Remove User', props<{ id: number }>());
export const removeUserSuccess = createAction('[user] Remove User Success', props<{ id: number }>());
export const removeUserFailed = createAction('[user] Remove User Failed');

// update
export const updateUser = createAction('[user] Update User', props<{ id: number; user: IUser }>());
export const updateUserSuccess = createAction('[user] Update User Success', props<{ user: Update<IUser> }>());
export const updateUserFailed = createAction('[user] Update User Failed');
