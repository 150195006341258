import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApplication from './application.reducer';
import { ApplicationFormStatus, Pageable } from '@model';

export const selectApplicationState = createFeatureSelector<fromApplication.IApplicationFormState>(fromApplication.APPLICATION_FORMS_STATE_KEY);

// get the selectors
const { selectEntities, selectAll, selectIds } = fromApplication.applicationAdapter.getSelectors();

export const selectApplicationIds = createSelector(selectApplicationState, selectIds);
export const selectApplicationEntities = createSelector(selectApplicationState, selectEntities);
export const selectAllApplications = createSelector(selectApplicationState, selectAll);
export const selectApplicationTotal = createSelector(selectApplicationState, state => state.total);

export const selectApplications = createSelector(selectAllApplications, selectApplicationTotal, (forms, total) => ({
  data: forms,
  total,
}));

export const selectPageableApplications = createSelector(
  selectApplicationState,
  ({ pageIndex, pageSize, sort, filters }): Pageable => ({
    pageIndex,
    pageSize,
    sort: sort!,
    filters,
  })
);

export const selectedApplicationForm = createSelector(selectApplicationState, state => state.selected);

export const getSelectedApplicationFormStatus = createSelector(selectedApplicationForm, appForm => appForm?.status || ApplicationFormStatus.DRAFT);
