import { BaseEntityState, IApplicationForm, IPagedApplicationForm, Pageable, Sort } from '@model';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  loadAllApplications,
  loadAllApplicationsFailed,
  loadAllApplicationsSuccess,
  loadApplicationByFormIDFailed,
  loadApplicationByFormIDSuccess,
  removeSelectedApplication,
} from './application.action';

export const APPLICATION_FORMS_STATE_KEY = 'application';

export interface IApplicationFormState extends Omit<Pageable, 'sort'>, BaseEntityState<IPagedApplicationForm> {
  sort: Sort | null;
  total: number;
  selected: IApplicationForm | null;
}

export const applicationAdapter: EntityAdapter<IPagedApplicationForm> = createEntityAdapter<IPagedApplicationForm>({
  selectId: (form: IPagedApplicationForm): string => form.formID!,
});

const initialState: IApplicationFormState = applicationAdapter.getInitialState({
  pageIndex: 0,
  pageSize: 0,
  sort: null,
  filters: [],
  total: 0,
  selected: null,
});

export const applicationFormReducer = createReducer(
  initialState,
  on(loadAllApplications, (state, { pageIndex, pageSize, sort, filters }) => ({
    ...state,
    pageIndex,
    pageSize,
    sort,
    filters,
  })),
  on(loadAllApplicationsSuccess, (state, { data, total }) => applicationAdapter.setAll(data, { ...state, total })),
  on(loadAllApplicationsFailed, state => applicationAdapter.removeAll({ ...state, total: 0 })),
  on(loadApplicationByFormIDSuccess, (state, { type, ...data }) => ({ ...state, selected: data })),
  on(loadApplicationByFormIDFailed, removeSelectedApplication, state => ({ ...state, selected: null }))
);
