import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTable, IAppDocument, IQuestion, Pageable } from '@model';

@Injectable({
  providedIn: 'root',
})
export class DocumentQuestsService {
  constructor(private http: HttpClient) {}

  scanDocument(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`/documents/scan`, formData);
  }

  uploadDocument(file: File, formID: string, uuid: string, questID: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('formID', formID);
    formData.append('id', uuid);
    formData.append('questID', questID);
    return this.http.post<{ data: string }>(`/documents/upload`, formData, { reportProgress: true, observe: 'events' });
  }

  getDocument(uuid: string): Observable<Blob> {
    return this.http.get(`/documents/preview/${uuid}`, { responseType: 'blob' });
  }

  getDocumentById(documentId: number) {
    return this.http.get(`/documents/${documentId}`, { responseType: 'arraybuffer' });
  }

  getDocuments(formID: string) {
    return this.http.get<IAppDocument[]>(`/documents/${formID}`);
  }

  removeDocument(fileName: string, formID: string, uuid: string): Observable<{ data: string }> {
    return this.http.delete<{ data: string }>(`/documents/remove/${formID}/${fileName}/${uuid}`);
  }

  uploadDocuments(files: File[]): Observable<{ data: string }> {
    const formData = new FormData();
    for (const f of files) {
      formData.append('files', f);
    }
    return this.http.post<{ data: string }>(`/documents/upload`, formData);
  }

  loadPageableDocQuest(pageable: Pageable): Observable<DataTable<IQuestion>> {
    return this.http.post<DataTable<IQuestion>>(`/setting/doc-quest/all`, { ...pageable });
  }

  loadAllDocQuest(): Observable<IQuestion[]> {
    return this.http.get<IQuestion[]>(`/setting/doc-quest/all`);
  }

  updateOne(id: number, question: IQuestion): Observable<IQuestion> {
    return this.http.put<IQuestion>(`/setting/doc-quest/${id}/update`, { ...question });
  }
}
