import { Component, OnInit } from '@angular/core';
import { CommonService } from '@services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sws-spinner',
  templateUrl: './sws-loading-spinner.component.html',
  styleUrl: './sws-loading-spinner.component.scss',
  standalone: false,
})
export class SwsLoadingSpinnerComponent implements OnInit {
  $loading!: BehaviorSubject<boolean>;
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.$loading = this.commonService.getLoading();
  }
}
