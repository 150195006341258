import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationBehaviorOptions, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { SwsButtonType } from '@model';
import { CommonService } from '@services';

@Component({
  selector: 'sws-page-header',
  templateUrl: './sws-page-header.component.html',
  styleUrl: './sws-page-header.component.scss',
  standalone: false,
})
export class SwsPageHeaderComponent implements OnInit {
  pageTitle$!: Observable<string>;
  destroyRef = inject(DestroyRef);

  to = input.required<Array<string | UrlTree | NavigationBehaviorOptions>>();
  text = input<string>('Back');
  type = input<SwsButtonType>('secondary');
  constructor(
    private readonly commonService: CommonService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.pageTitle$ = this.commonService.getTitle().pipe(takeUntilDestroyed(this.destroyRef));
  }

  async handleBack() {
    const toUrl = this.to();
    if (toUrl && Array.isArray(toUrl)) {
      await this.router.navigateByUrl(this.to().join('/'), {});
    }
  }
}
