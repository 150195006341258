import { Attribute, Component, inject, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { GenderType } from '@model';
import { CommonService } from '@services';

@Component({
  selector: 'sws-spouse-info',
  templateUrl: './spouse-info.component.html',
  styleUrls: ['./spouse-info.component.scss'],
  standalone: false,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SpouseInfoComponent implements OnInit {
  form!: FormGroup;
  genderTypes!: GenderType[];
  sexualities!: GenderType[];

  constructor(
    @Attribute('formGroupName') readonly formGroupName: string,
    private readonly commonService: CommonService,
    private readonly rootForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.genderTypes = this.commonService.genderTypes;
    this.sexualities = this.commonService.sexualities;
    this.form = this.rootForm.control.get(this.formGroupName) as FormGroup;
  }

  get dateOfBirth(): AbstractControl {
    return this.form.get('dateOfBirth') as AbstractControl;
  }

  sortById = this.commonService.sortById;
}
