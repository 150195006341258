import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IRole, Pageable } from '@model';

// load
export const loadAllRoles = createAction('[role] Load All Roles', props<Pageable>());
export const loadAllRolesSuccess = createAction('[role] Load All Roles Success', props<{ data: IRole[]; total: number }>());
export const loadAllRolesFailed = createAction('[role] Load All Roles Failed');

// create
export const addRole = createAction('[role] Add New Role', props<{ data: IRole }>());
export const addRoleSuccess = createAction('[role] Add New Role Success', props<{ data: IRole }>());
export const addRoleFailed = createAction('[role] Add New Role Failed');

// remove
export const removeRole = createAction('[role] Remove Role', props<{ id: number }>());
export const removeRoleSuccess = createAction('[role] Remove Role Success', props<{ id: number }>());
export const removeRoleFailed = createAction('[role] Remove Role Failed');

// update
export const updateRole = createAction('[role] Update Role', props<{ id: number; role: IRole }>());
export const updateRoleSuccess = createAction('[role] Update Role Success', props<Update<IRole>>());
export const updateRoleFailed = createAction('[role] Update Role Failed');
