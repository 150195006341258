import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IDepartment, Pageable } from '@model';

// load
export const loadAllDepartments = createAction('[department] Load All Departments', props<Pageable>());
export const loadAllDepartmentsSuccess = createAction('[department] Load All Departments Success', props<{ data: IDepartment[]; total: number }>());
export const loadAllDepartmentsFailed = createAction('[department] Load All Departments Failed');

// create
export const addDepartment = createAction('[department] Add New Department', props<{ data: IDepartment }>());
export const addDepartmentSuccess = createAction('[department] Add New Department Success', props<{ data: IDepartment }>());
export const addDepartmentFailed = createAction('[department] Add New Department Failed');

// remove
export const removeDepartment = createAction('[department] Remove Department', props<{ id: number }>());
export const removeDepartmentSuccess = createAction('[department] Remove Department Success', props<{ id: number }>());
export const removeDepartmentFailed = createAction('[department] Remove Department Failed');

// update
export const updateDepartment = createAction('[department] Update Department', props<{ id: number; department: IDepartment }>());
export const updateDepartmentSuccess = createAction('[department] Update Department Success', props<Update<IDepartment>>());
export const updateDepartmentFailed = createAction('[department] Update Department Failed');
