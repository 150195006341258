import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mmDigit',
  standalone: false,
})
export class SwsMmDigitPipe implements PipeTransform {
  private readonly myanmarNumbers = ['၀', '၁', '၂', '၃', '၄', '၅', '၆', '၇', '၈', '၉'];

  transform(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    // Ensure the input is a string to transform character by character.
    const input = value.toString();

    return input.replace(/\d/g, digit => this.myanmarNumbers[+digit]);
  }
}
