<form
  nz-form
  novalidate
  nzLayout="vertical"
  [nzNoColon]="true"
  [formGroup]="form"
  #f="ngForm"
  (ngSubmit)="onSubmit($event, f.form)"
  class="application-form">
  <nz-row nzJustify="space-between">
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="name" nzRequired
          >Full Name&nbsp;<span class="sws-text-muted">(Rakhine)</span><span class="sws-text-required">*</span></nz-form-label
        >
        <nz-form-control nzErrorTip="Full name is required.">
          <input sws-input nz-input id="name" name="name" type="text" formControlName="name" placeholder="Enter full name (Rakhine)" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="occupation" nzRequired>Postion / Occupation<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Position or occupation is required.">
          <input
            sws-input
            nz-input
            type="text"
            id="occupation"
            name="occupation"
            formControlName="occupation"
            placeholder="Enter position or occupation" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="organization" nzRequired>Organization<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Organization is required.">
          <input
            sws-input
            nz-input
            formControlName="organization"
            type="text"
            id="organization"
            name="organization"
            placeholder="Enter organization" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="7">
      <nz-form-item>
        <nz-form-label nzFor="isMember" nzRequired>Party Member?<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Party member is required.">
          <nz-radio-group id="isMember" formControlName="isMember" (ngModelChange)="onMemberStatusChange($event)">
            <label [for]="'Yes'" sws-radio nz-radio [nzValue]="true">Yes</label>
            <label [for]="'No'" sws-radio nz-radio [nzValue]="false">No</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="17" [ngStyle]="{ opacity: isMember.value ? 1 : 0 }">
      <nz-form-item>
        <nz-form-label nzFor="memberSerialNo" nzRequired>Member Serial Number<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Member serial number is required.">
          <input
            sws-input
            nz-input
            type="text"
            id="memberSerialNo"
            name="memberSerialNo"
            formControlName="memberSerialNo"
            placeholder="Enter party member serial number" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzFlex="100%">
      <nz-form-item>
        <sws-input-phonenumber id="contactNo" swsRequired="Contact number is required." formControlName="contactNo">
          Contact No
        </sws-input-phonenumber>
      </nz-form-item>
    </nz-col>
    <nz-col nzFlex="100%">
      <nz-form-item>
        <nz-form-label nzFor="remark">Remark&nbsp;<span class="sws-text-muted"> (Optional) </span></nz-form-label>
        <nz-form-control>
          <sws-textarea id="remark" formControlName="remark" placeholder="Enter Remark"></sws-textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <sws-button-group>
    <button sws-button swsType="primary" type="submit" nz-button nzType="primary">Submit</button>
  </sws-button-group>
</form>
