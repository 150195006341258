import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCity from './city.reducer';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { NRC_TYPES } from '@model';
import { toTitleCase } from '@util';

export const selectCityState = createFeatureSelector<fromCity.ICityState>(fromCity.CITY_STATE_KEY);

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = fromCity.cityAdapter.getSelectors();

export const selectStateIds = createSelector(selectCityState, selectIds);
export const selectCityGroupEntities = createSelector(selectCityState, selectEntities);
export const selectAllCityGroups = createSelector(selectCityState, selectAll);
export const selectCityGroupTotal = createSelector(selectCityState, selectTotal);

export const selectByStateId = (stateId: number) => createSelector(selectCityGroupEntities, entities => entities[stateId]!);

export const selectCitiesAsOptions = createSelector(selectAllCityGroups, divisions => {
  if (divisions.length < 1) return [];
  const data = divisions.map(division => {
    const temp: NzCascaderOption = {
      label: `${division.code.en} - ${toTitleCase(division.shortName.en)}`,
      value: division.code.en,
      children: division.cities.map(c => {
        let temp = toTitleCase(c.nameEN);
        if (temp.includes('-u')) {
          temp = temp.replace('-u', '-U');
        }
        return {
          label: `${temp} - (${c.shortEN})`,
          value: c.shortEN,
          children: NRC_TYPES.map<NzCascaderOption>(tp => ({
            label: `${tp.name['en']}`,
            value: tp.name['en'],
            isLeaf: true,
          })),
        };
      }),
    };
    return temp;
  });

  const specialItem = data[data.findIndex(val => val.value === '9*')];
  const filterData = data.filter(a => a.value !== '9*').sort((a, b) => parseInt(a.value) - parseInt(b.value));
  const index = filterData.findIndex(val => val.value === '9') + 1;
  const sortedData = [...filterData.slice(0, index), specialItem, ...filterData.slice(index)];
  return sortedData;
});
