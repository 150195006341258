import { Component, Input } from '@angular/core';

@Component({
  selector: 'sws-form-text',
  templateUrl: './sws-form-text.component.html',
  styleUrl: './sws-form-text.component.scss',
  standalone: false,
})
export class SwsFormTextComponent {
  @Input() text = '';
}
