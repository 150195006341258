import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormGroupDirective } from '@angular/forms';
import { ApplicationFormService, CommonService } from '@services';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { EducationStatus } from '@model';
import { validateForm } from '@util';
import { ModalForm, ModalFormType } from '@shared/form/common.interface';

@Component({
  selector: 'sws-education-form',
  templateUrl: './education-form.component.html',
  styleUrl: './education-form.component.scss',
  standalone: false,
})
export class EducationFormComponent implements ModalForm, OnInit {
  form!: FormGroup;
  nzData: { data: FormArray } = inject(NZ_MODAL_DATA);
  educationStatus!: EducationStatus[];
  constructor(
    private readonly appFormService: ApplicationFormService,
    private readonly commonService: CommonService,
    private readonly modalRef: NzModalRef<EducationFormComponent, ModalFormType>
  ) {}

  ngOnInit(): void {
    this.educationStatus = this.commonService.educationStatus;
    this.form = this.appFormService.createEducationForm();
  }

  disabledDate = this.commonService.disabledDate;

  onSubmit(event: SubmitEvent, { form: data }: FormGroupDirective) {
    event.preventDefault();
    if (data.invalid) {
      validateForm(data);
      return;
    }
    this.nzData.data.push(data);
    this.modalRef.close(this.nzData);
  }
}
