import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMismatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const currentPassword = control.get('currentPassword')?.value;
    const newPassword = control.get('newPassword')?.value;
    const confirmNewPassword = control.get('confirmPassword')?.value;
    if (newPassword && currentPassword && newPassword === currentPassword) {
      return { currentAndNewPasswordSame: true };
    }
    if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
      return { newPasswordMismatch: true };
    }
    return null;
  };
}
