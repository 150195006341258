import { IDivision } from '@model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { loadAllCities, loadAllCitiesFailed, loadAllCitiesSuccess } from './city.action';

export const CITY_STATE_KEY = 'city';

export interface ICityState extends EntityState<IDivision> {
  isLoading: boolean;
}

const selectStateId = (group: IDivision): number => group.id;

export const cityAdapter: EntityAdapter<IDivision> = createEntityAdapter<IDivision>({
  selectId: selectStateId,
});

const initialCityState: ICityState = cityAdapter.getInitialState({
  isLoading: false,
});

export const cityReducer = createReducer(
  initialCityState,
  on(loadAllCities, state => ({ ...state, isLoading: true })),
  on(loadAllCitiesSuccess, (state, { data }) => {
    return cityAdapter.setAll(data, { ...state, isLoading: false });
  }),
  on(loadAllCitiesFailed, state => {
    return cityAdapter.removeAll({ ...state, isLoading: false });
  })
);
