import { BaseEntityState, IUser, Pageable, Sort } from '@model';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.action';

export const USER_STATE_KEY = 'user';

export interface UserState extends Omit<Pageable, 'sort'>, BaseEntityState<IUser> {
  sort: Sort | null;
  total: number;
}

const selectUserId = (user: IUser): number => user.id;

export const userAdapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({
  selectId: selectUserId,
});

export const initialUserState: UserState = userAdapter.getInitialState({
  pageIndex: 0,
  pageSize: 0,
  sort: null,
  filters: [],
  total: 0,
});

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.loadAllUsers, (state, { pageIndex, pageSize, sort, filters }) => ({
    ...state,
    pageIndex,
    pageSize,
    sort,
    filters,
  })),
  on(UserActions.loadAllUsersSuccess, (state, { users, total }) => userAdapter.setAll(users, { ...state, total })),
  on(UserActions.loadAllUsersFailed, state => userAdapter.removeAll({ ...state, total: 0 })),
  on(UserActions.addUserSuccess, (state, data) => userAdapter.addOne(data, { ...state, total: state.total + 1 })),
  on(UserActions.removeUserSuccess, (state, { id }) => userAdapter.removeOne(id, { ...state, total: state.total - 1 })),
  on(UserActions.updateUserSuccess, (state, { user }) => userAdapter.updateOne(user, { ...state }))
);
