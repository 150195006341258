import { inject, InjectionToken, Type } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { SwsConfirmationComponent } from '@components';
import { SearchFilter, SearchTerm, SwsDataType } from '@model';
import { ModalForm, ModalFormType } from '@shared/form/common.interface';
import { format, parse } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';

export const DEFAULT_DATE_PATTERN = 'yyyy-MM-dd';

export const toFeet = (value: number) => {
  const realFeet = (value * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return [feet, inches];
};

export const toCentimeter = (value: number[]) => {
  return (value[0] * 12 + value[1]) * 2.54;
};

export const toDate = (date: string | Date) => {
  if (date instanceof Date) {
    return date;
  }
  if (date.includes('T')) {
    return new Date(date);
  }
  return parse(date, DEFAULT_DATE_PATTERN, new Date());
};

export function toTitleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const formatPhoneNumber = (phoneNumber: string) => {
  // Check if the number has Myanmar digits after +95
  if (/^\+95[\u1040-\u1049]/.test(phoneNumber)) {
    return phoneNumber.replace(/^\+95/, '(+၉၅)'); // Myanmar format
  }

  // Otherwise, it's an English number, so keep the standard +95 format
  return phoneNumber.replace(/^\+95/, '(+95)'); // English format
};

export function replaceMmDigitWithEn(input: string) {
  if (!isMmDigit(input)) return input;
  // Use replace with a callback function to convert Unicode Myanmar digits to English digits
  return input.replace(/[\u1040-\u1049]/g, match => {
    // Convert Myanmar digit Unicode (U+1040 to U+1049) to English digit (0 to 9)
    return String.fromCharCode(match.charCodeAt(0) - 0x1040 + 48);
  });
}

function isMmDigit(input: string) {
  // Check if the input contains only Myanmar digits (Unicode U+1040 to U+1049)
  return /^[\u1040-\u1049]+$/.test(input);
}

export const validateForm = (form: FormGroup | FormArray) => {
  if (form.invalid) {
    Object.values(form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
        if (control instanceof FormGroup) {
          if (control.invalid) {
            validateForm(control);
          }
        } else if (control instanceof FormArray) {
          control.controls.forEach(ctrl => {
            if (ctrl.invalid) {
              if (ctrl instanceof FormGroup) {
                validateForm(ctrl);
              } else {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
            }
          });
        }
      }
    });
  }
};

export function filterMixin<T extends Record<string, unknown>, R extends Record<keyof T, SearchTerm>>(data: T, specified: R) {
  return Object.entries(data)
    .map<SearchFilter>(([k, v]) => ({
      key: k,
      value: Array.isArray(v) ? [...v.filter(temp => !!temp).map(val => dateToString(val))] : v ? [dateToString(v as string)] : [],
      term: specified[k],
    }))
    .filter(({ value }) => (value as unknown[]).length > 0 && (value as unknown[]).every(i => !!i));
}

const dateToString = (value: string | number | boolean | Date) => {
  if (value instanceof Date) {
    return format(value, DEFAULT_DATE_PATTERN);
  }
  return value;
};

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const trimText = (value: string | undefined): string => {
  return value?.trim() ?? '';
};

export enum FORM_KEY {
  APPLICATION = '_f_app',
  USER = 'f_user',
  ROLE = 'f_role',
  DEPARTMENT = 'f_department',
}

export const SWS_MODAL_FORM = new InjectionToken('SWS_MODAL_FORM', {
  providedIn: 'root',
  factory: () => {
    const modalService = inject(NzModalService);
    const callback = <T extends AbstractControl>(title: string, type: Type<ModalForm>, data: T) => {
      const modalRef = modalService.create<ModalForm, ModalFormType>({
        nzTitle: title,
        nzContent: type,
        nzFooter: null,
        nzWidth: '50%',
        nzClosable: true,
        nzKeyboard: false,
        nzMaskClosable: false,
        nzCentered: false,
        nzStyle: {
          top: '64px',
        },
        nzData: {
          data,
        },
        nzOnCancel: () => Promise.resolve(() => modalRef.close()),
      });
    };
    return callback;
  },
});

export const errorConfirmModal = (modal: NzModalService, title: string, message: string, onClose?: () => void) => {
  const modalRef = modal.create<SwsConfirmationComponent, SwsDataType, boolean>({
    nzContent: SwsConfirmationComponent,
    nzFooter: null,
    nzClosable: false,
    nzMaskClosable: false,
    nzModalType: 'default',
    nzClassName: 'sws-confirmation-modal',
    nzKeyboard: false,
    nzWidth: '30%',
    nzCentered: true,
    nzData: {
      onOk: () => {
        if (onClose) {
          onClose();
        }
        modalRef.close();
      },
      content: message,
      title: title,
      okText: 'Close',
    },
  });
};

export const logoutConfirmModal = (modal: NzModalService, title: string, message: string, onClose: () => void) => {
  const modalRef = modal.create<SwsConfirmationComponent, SwsDataType, boolean>({
    nzContent: SwsConfirmationComponent,
    nzFooter: null,
    nzClosable: false,
    nzMaskClosable: false,
    nzModalType: 'default',
    nzClassName: 'sws-confirmation-modal',
    nzKeyboard: false,
    nzWidth: '30%',
    nzCentered: true,
    nzData: {
      onOk: async () => {
        if (onClose) {
          onClose();
        }
        modalRef.close(true);
      },
      onCancel: () => {
        modalRef.close(false);
      },
      content: message,
      title: title,
      okText: 'Yes',
      cancelText: 'No',
    },
  });
  return modalRef;
};
