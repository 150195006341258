import { HttpEventType } from '@angular/common/http';
import { Component, computed, DestroyRef, Input, input, OnDestroy, OnInit, output, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { SwsImagePreviewComponent } from '@components';
import { ApplicationFormStatus, IQuestion } from '@model';
import { ApplicationFormService, DocumentQuestsService } from '@services';
import { getBase64, NotificationMessage } from '@util';
import { every as _every, groupBy as _groupBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalComponent, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { catchError, filter, from, map, Observable, Subject, Subscription, switchMap, take, tap, throwError } from 'rxjs';

@Component({
  selector: 'sws-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
  standalone: false,
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {
  data = input.required<{ formID: string; status: ApplicationFormStatus; questions: Array<IQuestion & { files: NzUploadFile[] }> }>();

  previewModal = viewChild<NzModalComponent>('previewModal');
  formStatus: ApplicationFormStatus = ApplicationFormStatus.DRAFT;
  uploadStatus = output<'invalid' | 'valid'>({ alias: 'statusChange' });

  private _hasError = false;
  private _questions: Array<IQuestion & { files: NzUploadFile[] }> = [];
  private _formID!: string;
  private _questionsSignal = toSignal(
    toObservable(this.data).pipe(
      filter(val => !!val && !!val.formID),
      take(1),
      tap(({ formID, status }) => {
        this._formID = formID;
        this.formStatus = status;
      }),
      switchMap(({ formID }) => this.documentService.getDocuments(formID)),
      takeUntilDestroyed()
    ),
    { initialValue: [] }
  );

  computedQuestions = computed(() => {
    const { questions } = this.data();
    const documents = this._questionsSignal();
    if (documents.length && questions.length) {
      const docsMap = _groupBy(documents, 'questID');
      this._questions = questions.map(quest => {
        const relatedDocs = docsMap[quest.id];
        const modifiedFiles = relatedDocs?.map(document => {
          const { data, ...doc } = document;
          const arr = data.split(',');
          const bstr = atob(arr[arr.length - 1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          // const blob = new Blob([u8arr]);
          const file = new File([u8arr], doc.fileName, { type: doc.fileType });
          const docFile: NzUploadFile = {
            filename: doc.fileName,
            type: doc.fileType,
            uid: doc.uuid,
            name: doc.fileName,
            originFileObj: file,
            questId: quest.id + '_' + doc.documentId,
          };
          return docFile;
        });
        if (modifiedFiles?.length) {
          return { ...quest, files: modifiedFiles };
        } else {
          return { ...quest, files: [] };
        }
      });
      return this._questions;
    } else if (questions.length) {
      this._questions = questions;
      return this._questions;
    } else {
      return [];
    }
  });

  @Input({ required: true, alias: 'finished' })
  finished!: Subject<void>;

  constructor(
    private readonly messageService: NzMessageService,
    private readonly documentService: DocumentQuestsService,
    private readonly applicationFormService: ApplicationFormService,
    private readonly router: Router,
    private readonly modalService: NzModalService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.finished.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: _ => {
        const valid = _every(
          this._questions,
          (quest: IQuestion & { files: NzUploadFile[] }) => quest.files.length !== 0 && quest.files.length <= quest.maxUploadLimit
        );
        if (!valid) {
          this.messageService.warning('Please upload all required documents!');
          this.uploadStatus.emit('invalid');
          return;
        }
        this.applicationFormService.checkForm(this._formID).subscribe({
          next: ({ data }) => {
            this.formStatus = data;
            this.messageService.success(NotificationMessage.SUCCESSFUL);
            this.uploadStatus.emit('valid');
            this.router.navigateByUrl('/application-form');
          },
          error: err => {
            this.messageService.error(`${NotificationMessage.FAILED}${err.error?.message ? ' - ' + err.error.message : ''}`);
          },
        });
      },
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.messageService.error('You can only upload JPEG or PNG file!');
      return false;
    }
    const isLt2M = file.size! / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.messageService.error('Image must smaller than 2MB!');
      return false;
    }

    return true;
  };

  handleUpload = (xhrItem: NzUploadXHRArgs): Subscription => {
    const { file, name, onProgress, onError, onSuccess } = xhrItem;
    const names = name!.split('_');
    const questID = names[names.length - 2];
    const questIndex = +names[names.length - 1];

    this._hasError = false;

    return this.documentService.uploadDocument(file as unknown as File, this._formID, file.uid, questID).subscribe({
      next: event => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            const percent = Math.round((event.loaded / event.total) * 100);
            onProgress!({ percent }, file); // Update progress
          }
        } else if (event.type === HttpEventType.Response) {
          this._hasError = false;
          this.messageService.success(NotificationMessage.SUCCESSFUL);
          onSuccess!(event.body, file, xhrItem); // Handle successful upload
        }
      },
      error: err => {
        this._hasError = true;
        onError!(err, file);
        this.removeFile(questIndex, file);
        this.messageService.error('Failed to upload!' + '( ' + err.error.message + ' )');
      },
      complete: () => {
        console.log('completed ....');
      },
    });
  };

  // Remove file from the list
  private removeFile(questIdx: number, file: NzUploadFile): void {
    const index = this._questions[questIdx].files.findIndex(f => f.uid === file.uid);
    if (index !== -1) {
      this._questions[questIdx].files.splice(index, 1); // Remove the file from the fileList array
      this._questions[questIdx].files = [...this._questions[questIdx].files];
    }
  }

  handleIsPreviewImage = (_: NzUploadFile): boolean => !this._hasError;

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    const previewImage = file.url || file['preview'] || file.thumbUrl;
    const modalRef = this.modalService.create<SwsImagePreviewComponent, { previewImage: string }>({
      nzTitle: this._questions.filter(quest => quest.id == file['questId'].split('_')[0])[0].titleEN,
      nzContent: SwsImagePreviewComponent,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzWidth: '85%',
      nzStyle: {
        top: '64px',
      },
      nzWrapClassName: 'sws-preview-wrapper',
      nzData: {
        previewImage: previewImage,
      },
      nzOnCancel: () => Promise.resolve(() => modalRef.close()),
    });
  };

  handlePreviewFile = (file: NzUploadFile): Observable<string> => {
    return from(getBase64(file.originFileObj!) as Promise<string>);
  };

  handleRemove = (file: NzUploadFile): Observable<boolean> => {
    return this.documentService.removeDocument(file.name, this._formID, file.uid).pipe(
      catchError(() => {
        this.messageService.error('Failed to remove!');
        return throwError(() => false);
      }),
      map(({ data }) => {
        this._questions.forEach(q => {
          const idx = q.files.findIndex(f => f.uid === file.uid);
          if (idx > -1) {
            q.files.splice(idx, 1);
          }
        });
        this.messageService.success(data);
        return true;
      })
    );
  };

  ngOnDestroy(): void {}
}
