import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { RouteTitles } from '@router';
import { ApplicationFormService } from '@services';
import {
  getCurrentFormID,
  IAppState,
  loadAllApplications,
  loadAllApplicationsFailed,
  loadAllApplicationsSuccess,
  loadApplicationByFormID,
  loadApplicationByFormIDFailed,
  loadApplicationByFormIDSuccess,
  MergedRoute,
} from '@store';
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';

@Injectable()
export class ApplicationFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly applicationFormService: ApplicationFormService,
    private readonly store: Store<IAppState>
  ) {}

  onApplicationFormDetailOrEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      map(({ payload }) => payload.routerState as unknown as MergedRoute),
      filter(({ title }) => title === RouteTitles.APPLICATION_UPDATE || title === RouteTitles.APPLICATION_DETAIL),
      withLatestFrom(this.store.select(getCurrentFormID)),
      filter(([_, id]) => !!id),
      map(([_, id]) => loadApplicationByFormID({ id: id as string }))
    )
  );

  loadByFormID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApplicationByFormID),
      mergeMap(({ id }) =>
        this.applicationFormService.findOne(id).pipe(
          map(data => loadApplicationByFormIDSuccess(data)),
          catchError(() => of(loadApplicationByFormIDFailed()))
        )
      )
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllApplications),
      mergeMap(({ pageIndex, pageSize, filters, sort }) => {
        return this.applicationFormService.loadApplicationForms({ pageIndex, pageSize, filters, sort }).pipe(
          map(({ data, total }) => loadAllApplicationsSuccess({ data: [...data], total })),
          catchError(() => of(loadAllApplicationsFailed()))
        );
      })
    )
  );

  // onAddApp$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DepartmentActions.addDepartment),
  //     mergeMap(({ data }) =>
  //       this.departmentService.create(data).pipe(
  //         map((createdDepartment) => {
  //           this.router.navigateByUrl('/department');
  //           return DepartmentActions.addDepartmentSuccess({ data: createdDepartment });
  //         }),
  //         catchError(() => of(DepartmentActions.addDepartmentFailed()))
  //       )
  //     )
  //   )
  // );

  // onUpdateDepartment$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DepartmentActions.updateDepartment),
  //     mergeMap(({ id, department }) =>
  //       this.departmentService.updateOne(id, department).pipe(
  //         map((data) => {
  //           this.router.navigateByUrl('/department');
  //           return DepartmentActions.updateDepartmentSuccess({
  //             changes: {
  //               ...data,
  //             },
  //             id: id!,
  //           });
  //         }),
  //         catchError(() => of(DepartmentActions.updateDepartmentFailed()))
  //       )
  //     )
  //   )
  // );
}
