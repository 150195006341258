import { Directive, HostListener, input } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { OnChangeFn } from '@model';

@Directive({
  selector: '[sws-number-only]',
  standalone: false,
})
export class SwsNumberOnlyDirective implements Validator {
  max = input<number>(0);
  leadingZero = input(true);
  private englishNumeralsRegex = /^[0-9]*$/;
  private myanmarNumeralsRegex = /^[\u1040-\u1049]*$/;
  private combinedRegex = /^(?:[0-9]*|[\u1040-\u1049]*)$/;

  // private regex = /^(?:[0-9]{6}|[\u1040-\u1049]{6})$/;
  _onChange: OnChangeFn<string> = () => {};
  constructor(private readonly ngControl: NgControl) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const isValid = this.combinedRegex.test(value) && value.length <= this.max();

    return isValid ? null : { invalidNumber: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onChange = fn;
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    const englishOnly = this.englishNumeralsRegex.test(value);
    const myanmarOnly = this.myanmarNumeralsRegex.test(value);

    if (!englishOnly && !myanmarOnly) {
      const sanitizedValue = value
        .split('')
        .filter(char => /[0-9\u1040-\u1049]/.test(char))
        .join('');
      value = sanitizedValue;
    }
    if (!this.leadingZero()) {
      value = value.replace(/^[0\u1040]/, '');
    }

    if (this.max() > 0) {
      value = value.substring(0, this.max());
    }
    this.ngControl?.control?.setValue(value, { emitEvent: false });
  }
}
