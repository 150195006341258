import { TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzCheckboxOption } from 'ng-zorro-antd/checkbox';

import { cscPrettyPrint, formatPhoneNumber } from '@util';
import { SwsColumnType } from '../datatable';
import { IDepartment } from '../department';
import { IRole } from '../role';

export enum UserStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
}

export interface IUser {
  contactNo: string;
  currentCSC: string;
  fullNameEN: string;
  fullNameRKE: string;
  id: number;
  userId: string;
  email: string;
  roles?: IRole[];
  department?: IDepartment;
  active?: boolean;
  status?: UserStatus;
  // credentialStatus?: CredentialStatus;
}

export interface UserForm {
  formRef: FormGroup;
  user: IUser;
}

export const userStatusLookup: NzCheckboxOption[] = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];

export const userTableColumns = (statusRef: TemplateRef<IUser>, actionRef: TemplateRef<IUser>): Array<SwsColumnType<IUser>> => {
  return [
    {
      key: 'userId',
      align: { header: 'center', cell: 'center' },
      width: '10%',
      title: 'User ID',
    },
    {
      key: 'fullNameEN',
      width: '18%',
      title: 'Full Name',
    },
    {
      key: 'email',
      width: '20%',
      title: 'Email',
      align: {
        header: 'center',
        cell: 'center',
      },
    },
    {
      key: 'currentCSC',
      title: 'Citizen Scrutiny Card (CSC)',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'function',
        transform: value => cscPrettyPrint(value as string)!,
      },
    },
    {
      key: 'contactNo',
      width: '10%',
      title: 'Mobile',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'function',
        transform: value => formatPhoneNumber(value as string),
      },
    },
    {
      key: 'status',
      width: '10%',
      title: 'Status',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: statusRef,
      },
    },
    {
      key: 'action',
      width: '120px',
      title: 'Action',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};
