export enum BloodType {
  A = 'A',
  B = 'B',
  AB = 'AB',
  O = 'O',
}

export enum EducationStatus {
  PASSED = 'passed',
  FAILED = 'failed',
}

export enum FormMode {
  UPDATE = 'update',
  CREATE = 'create',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
  NOTMENTIONED = 'Not Mentioned',
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}
