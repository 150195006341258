<ng-container [formGroup]="form">
  <sws-page-subtitle>
    <ng-template sws-title>Spouse Personal Information</ng-template>
  </sws-page-subtitle>
  <nz-row nzJustify="space-between">
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fullNameRKE" nzRequired> Full Name (Rakhine) <span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Full Name (Rakhine) is required.">
          <input
            nz-input
            sws-input
            type="text"
            id="fullNameRKE"
            name="fullNameRKE"
            formControlName="fullNameRKE"
            placeholder="Enter full name (Rakhine)" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fullNameEN" nzRequired>Full Name (English) <span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Full name (English) is required."
          ><input
            nz-input
            sws-input
            type="text"
            id="fullNameEN"
            name="fullNameEN"
            formControlName="fullNameEN"
            placeholder="Enter full name (English)"
        /></nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="fatherName" nzRequired>Father's Name <span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Father's name is required."
          ><input
            sws-input
            type="text"
            nz-input
            formControlName="fatherName"
            id="fatherName"
            name="fatherName"
            placeholder="Enter father's name."
        /></nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-csc formControlName="currentCSC"> NRC / CSC </sws-input-csc>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="occupation" nzRequired>Occupation<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Occupation is required.">
          <input
            sws-input
            type="text"
            nz-input
            formControlName="occupation"
            id="occupation"
            name="occupation"
            placeholder="Enter occupation" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <sws-input-dob formControlName="dateOfBirth">Date of Birth </sws-input-dob>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="gender" nzRequired>Gender<span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Gener is required.">
          <nz-radio-group formControlName="gender" nzName="gender" id="gender">
            @for (g of genderTypes; track g.id) {
              <label [for]="g.label" sws-radio nz-radio [nzValue]="g.value">{{ g.label }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="genderOrientation" nzRequired>Interested In<span class="sws-text-required">*</span> </nz-form-label>
        <nz-form-control nzErrorTip="Sexuality is required.">
          <nz-radio-group formControlName="genderOrientation" id="genderOrientation" nzName="genderOrientation">
            @for (g of sortById(sexualities); track g.id) {
              <label [for]="g.label" sws-radio nz-radio [nzValue]="g.value">{{ g.label }}</label>
            }
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="race" nzRequired>Race<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Race is required.">
          <input sws-input nz-input type="text" formControlName="race" id="race" name="race" placeholder="Enter race" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="religion" nzRequired>Religion<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Religion is required.">
          <input sws-input nz-input type="text" formControlName="religion" id="religion" name="religion" placeholder="Enter religion" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-phonenumber id="firstPhoneNumber" swsRequired="Permanent phone number is required." formControlName="firstPhoneNumber">
          Permanent Contact No
        </sws-input-phonenumber>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <sws-input-phonenumber id="secondPhoneNumber" formControlName="secondPhoneNumber">
          Secondary Contact No&nbsp;<span class="sws-text-muted">(Optional)</span>
        </sws-input-phonenumber>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="email" nzRequired>Email<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Email is required.">
          <input sws-input nz-input type="email" id="email" formControlName="email" name="email" placeholder="Enter email" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11"></nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="permanentAddress" nzRequired>Permanent Address<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Permanent address is required.">
          <sws-textarea id="permanentAddress" formControlName="permanentAddress" placeholder="Enter Permanent Address" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label nzFor="currentAddress" nzRequired>Current Address<span class="sws-text-required">*</span></nz-form-label>
        <nz-form-control nzErrorTip="Current address is required.">
          <sws-textarea id="currentAddress" placeholder="Enter Current Address" formControlName="currentAddress" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</ng-container>
