export { SwsLogout } from './outline/logout.icon';
export { SwsPencilSquare } from './outline/pencil-square.icon';
export { SwsPersonSquare } from './outline/person-square.icon';
export { SwsPlusCircle } from './outline/plus-circle.icon';
export { SwsTrash } from './outline/trash.icon';

export { SwsArrowLeftCircleFill } from './fill/arrow-left-circle.icon';
export { SwsArrowRightCircleFill } from './fill/arrow-right-circle.icon';
export { SwsCheckCircleFill } from './fill/check-circle.icon';
export { SwsDiagramFill } from './fill/diagram.icon';
export { SwsEyeSlashFille } from './fill/eye-slash.icon';
export { SwsFileTextFill } from './fill/file-text.icon';
export { SwsGearFill } from './fill/gear.icon';
export { SwsInfoCircleFill } from './fill/info-circle.icon';
export { SwsLayersFill } from './fill/layers.icon';
export { SwsPeopleFill } from './fill/people.icon';
export {
  SwsFiveCircleFill,
  SwsFourCircleFill,
  SwsOneCircleFill,
  SwsSixCircleFill,
  SwsThreeCircleFill,
  SwsTwoCircleFill,
} from './fill/steps.icon';
