import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ApplicationFormService, CommonService } from '@services';
import { ModalForm, ModalFormType } from '@shared/form/common.interface';
import { validateForm } from '@util';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'sws-experience-form',
  templateUrl: './experience-form.component.html',
  styleUrl: './experience-form.component.scss',
  standalone: false,
})
export class ExperienceFormComponent implements ModalForm, OnInit {
  form!: FormGroup;
  nzData: { data: FormArray } = inject(NZ_MODAL_DATA);

  constructor(
    private readonly appFormService: ApplicationFormService,
    private readonly commonService: CommonService,
    private readonly modalRef: NzModalRef<ExperienceFormComponent, ModalFormType>
  ) {}

  ngOnInit(): void {
    this.form = this.appFormService.createExperienceForm();
  }

  disabledDate = this.commonService.disabledDate;

  onSubmit(event: SubmitEvent, data: FormGroup) {
    event.preventDefault();
    if (data.invalid) {
      validateForm(data);
      return;
    }
    this.nzData.data.push(data);
    this.modalRef.close(this.nzData);
  }
}
