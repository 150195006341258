import { Component, input } from '@angular/core';

@Component({
  selector: 'sws-collapse',
  templateUrl: './sws-collapse.component.html',
  styleUrl: './sws-collapse.component.scss',
  standalone: false,
})
export class SwsCollapseComponent {
  title = input.required<string>();
}
