import { Attribute, Component, inject, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { MaritalStatus } from '@model';
import { ApplicationFormService } from '@services';

@Component({
  selector: 'sws-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrl: './applicant-info.component.scss',
  standalone: false,
  viewProviders: [{ provide: ControlContainer, useFactory: () => inject(ControlContainer, { skipSelf: true }) }],
})
export class ApplicantInfoComponent implements OnInit {
  applicantMaritalStatus: MaritalStatus | null = null;
  form!: FormGroup;
  spouseForm?: string;
  constructor(
    @Attribute('formGroupName') readonly formGroupName: string,
    private readonly rootForm: FormGroupDirective,
    private readonly appFormService: ApplicationFormService
  ) {}

  ngOnInit(): void {
    this.form = this.rootForm.control.get(this.formGroupName) as FormGroup;
  }

  onMaritalStatusChange(status: MaritalStatus) {
    this.applicantMaritalStatus = status;
    if (status === MaritalStatus.MARRIED) {
      this.spouseForm = 'spouseInfo';
      this.form.addControl('spouseInfo', this.appFormService.createPersonalInfoForm(true));
    }
  }
}
