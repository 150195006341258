import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
  standalone: false,
})
export class SwsAgePipe implements PipeTransform {
  transform(birthdate: string): number | void {
    if (!birthdate) {
      return;
    }
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    return age;
  }
}
