export enum RouteTitles {
  LOGIN = 'Login',
  CHANGE_PASSWORD = 'Change Password',
  APPLICATION = 'Applications',
  APPLICATION_CREATE = 'Create New Application',
  APPLICATION_UPDATE = 'Update Application Information',
  APPLICATION_DETAIL = 'Application Information',
  DEPARTMENT = 'Departments',
  DEPARTMENT_CREATE = 'Create New Department',
  DEPARTMENT_UPDATE = 'Update Department Information',
  USER = 'Users',
  USER_CREATE = 'Create New User',
  USER_UPDATE = 'Update User Information',
  ROLE = 'Roles',
  ROLE_CREATE = 'Create New Role',
  ROLE_UPDATE = 'Update Role Information',
  SETTING = 'Settings',
  DOCUMENT = 'Document Questions',
  DOCUMENT_CREATE = 'Create New Document Question',
  DOCUMENT_UPDATE = 'Update Document Question',
}

export enum RouteURI {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'change-password',
  APPLICATION_FORM = 'application-form',
  DEPARTMENT = 'department',
  USER = 'user',
  ROLE = 'role',
  SETTING = 'setting',
  DOCUMENT = 'setting/document',
  LIST = 'list',
  CREATE = 'create',
  UPDATE = 'update',
  DETAIL = 'detail',
}

export enum BreadCrumb {
  LOGIN = 'Login',
  CHANGE_PASSWORD = 'Change Password',
  APPLICATION_FORM = 'Application Form',
  DEPARTMENT = 'Department',
  USER = 'User',
  ROLE = 'Role',
  SETTING = 'Setting',
  LIST = 'List',
  CREATE = 'Create',
  UPDATE = 'Update',
  DETAIL = 'Detail',
}
