import { createComponent, EnvironmentInjector, inject, NgModule } from '@angular/core';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzWaterMarkModule } from 'ng-zorro-antd/water-mark';

import { IconDefinition } from '@ant-design/icons-angular';
import {
  ArrowLeftOutline,
  ArrowRightOutline,
  CaretDownOutline,
  CaretLeftOutline,
  CaretRightOutline,
  CaretUpOutline,
  CloseCircleFill,
  CloudUploadOutline,
  FileProtectOutline,
  LoadingOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  QuestionCircleOutline,
  SearchOutline,
  SyncOutline,
} from '@ant-design/icons-angular/icons';
import { GlobalIndicatorComponent } from '@components';
import * as swsIcons from '@icons';
import { enUS } from 'date-fns/locale';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { provideNzWave } from 'ng-zorro-antd/core/wave';
import { en_US, NZ_DATE_LOCALE, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzIconModule, provideNzIcons } from 'ng-zorro-antd/icon';

const icons: IconDefinition[] = [
  CloseCircleFill,
  SyncOutline,
  CloudUploadOutline,
  QuestionCircleOutline,
  SearchOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  FileProtectOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  CaretRightOutline,
  CaretLeftOutline,
  CaretDownOutline,
  CaretUpOutline,
  LoadingOutline,
];

// The factory function
const nzConfigFactory = (): NzConfig => {
  const environmentInjector = inject(EnvironmentInjector);
  const { nzIndicator } = createComponent(GlobalIndicatorComponent, { environmentInjector }).instance;
  return {
    spin: {
      nzIndicator,
    },
    message: { nzDuration: 5 * 1000 },
    select: { nzSuffixIcon: 'caret-down' },
    collapse: { nzAccordion: false, nzGhost: false, nzBordered: true },
    table: { nzBordered: true, nzSize: 'default', nzHideOnSinglePage: false },
    tabs: { nzAnimated: true, nzType: 'card' },
    notification: { nzDuration: 5 * 1000 },
  };
};

@NgModule({
  declarations: [],
  imports: [
    NzToolTipModule,
    NzLayoutModule,
    NzMenuModule,
    NzGridModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzButtonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDescriptionsModule,
    NzTableModule,
    NzTypographyModule,
    NzDividerModule,
    NzBreadCrumbModule,
    NzStepsModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzRadioModule,
    NzSelectModule,
    NzCascaderModule,
    NzCardModule,
    NzModalModule,
    NzDrawerModule,
    NzTabsModule,
    NzAffixModule,
    NzTagModule,
    NzUploadModule,
    NzListModule,
    NzWaterMarkModule,
    NzResultModule,
    NzAlertModule,
    NzSpinModule,
    NzSwitchModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzIconModule,
  ],
  exports: [
    NzToolTipModule,
    NzLayoutModule,
    NzMenuModule,
    NzGridModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzButtonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDescriptionsModule,
    NzTableModule,
    NzTypographyModule,
    NzDividerModule,
    NzBreadCrumbModule,
    NzStepsModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzRadioModule,
    NzSelectModule,
    NzCascaderModule,
    NzCardModule,
    NzModalModule,
    NzDrawerModule,
    NzTabsModule,
    NzAffixModule,
    NzTagModule,
    NzUploadModule,
    NzListModule,
    NzWaterMarkModule,
    NzResultModule,
    NzAlertModule,
    NzSpinModule,
    NzSwitchModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzIconModule,
  ],
  providers: [
    provideNzWave({ disabled: true }),
    provideNzI18n(en_US),
    provideNzIcons([...Object.values(swsIcons), ...icons]),
    { provide: NZ_DATE_LOCALE, useValue: enUS },
    { provide: NZ_CONFIG, useFactory: nzConfigFactory },
  ],
})
export class NgZorroAntDModule {}
