import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[sws-title]',
  standalone: false,
})
export class SwsTitleDirective {
  title = inject(TemplateRef<unknown>);
  constructor() {}
}
