import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  // eslint-disable-next-line no-useless-escape
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[\]{}|;:'",<>\./?]).{12,}$/;
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && !passwordPattern.test(value)) {
      return { invalidPassword: true };
    }
    return null;
  };
}
