import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[sws-radio]',
  standalone: false,
})
export class SwsRadioDirective implements OnInit {
  constructor(private elementRef: ElementRef<HTMLLabelElement>) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add('sws-radio-wrapper');
  }
}
