import { Attribute, Component, contentChild, inject, input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { FamilyMemberFormComponent } from '@components';
import { SwsTitleDirective } from '@directives';
import { GenderType, IFamilyMemberInfo, membersTableColumns, SwsColumnType } from '@model';
import { Store } from '@ngrx/store';
import { CommonService } from '@services';
import { getSelectedApplicationFormStatus, IAppState } from '@store';
import { SWS_MODAL_FORM } from '@util';

@Component({
  selector: 'sws-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.scss'],
  standalone: false,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class FamilyMembersComponent implements OnInit {
  formStatus$ = inject(Store<IAppState>)
    .select(getSelectedApplicationFormStatus)
    .pipe(takeUntilDestroyed());

  parent = input<string>();
  form!: FormArray;
  titleContent = contentChild(SwsTitleDirective);
  genderTypes!: GenderType[];
  @ViewChild('actionRef', { static: true })
  actionRef!: TemplateRef<number>;
  columns!: Array<SwsColumnType<IFamilyMemberInfo>>;
  swsModal = inject(SWS_MODAL_FORM);

  constructor(
    @Attribute('formArrayName') readonly formArrayName: string,
    private readonly commonService: CommonService,
    private readonly rootForm: FormGroupDirective
  ) {
    this.genderTypes = this.commonService.genderTypes;
  }

  ngOnInit(): void {
    if (this.parent()) {
      this.form = this.rootForm.control.get([this.parent()!, this.formArrayName]) as FormArray;
    } else {
      this.form = this.rootForm.control.get(this.formArrayName) as FormArray;
    }
    this.columns = membersTableColumns(this.actionRef);
  }

  addChildren = () => this.swsModal('Add New Family Member', FamilyMemberFormComponent, this.form);

  get members(): IFamilyMemberInfo[] {
    return this.form?.value || [];
  }

  remove(index: number) {
    this.form.removeAt(index);
  }
}
