import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[sws-tag]',
  standalone: false,
})
export class SwsTagDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    this.elementRef.nativeElement.classList.add('sws-tag');
  }
}
