import { TemplateRef } from '@angular/core';
import { SwsColumnType } from '../datatable';

export interface IRole {
  id?: number;
  code: string;
  nameEN: string;
  nameRKE: string;
  description: string;
  screens: string[];
}

export const roleTableColumns = (actionRef: TemplateRef<IRole>): Array<SwsColumnType<IRole>> => {
  return [
    {
      key: 'id',
      align: { header: 'center', cell: 'center' },
      width: '8%',
      title: 'ID',
    },
    {
      key: 'code',
      align: { header: 'center', cell: 'center' },
      width: '15%',
      title: 'Role Code',
    },
    {
      key: 'nameEN',
      width: '25%',
      title: 'Role Name',
    },
    {
      key: 'description',
      // width: '20%',
      title: 'Role Description',
      align: {
        header: 'center',
        cell: 'center',
      },
    },
    {
      key: 'action',
      width: '120px',
      title: 'Action',
      align: {
        header: 'center',
        cell: 'center',
      },
      value: {
        type: 'template',
        transform: actionRef,
      },
    },
  ];
};
