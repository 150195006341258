<sws-page-subtitle>
  <ng-template sws-title>Upload Documents</ng-template>
</sws-page-subtitle>

<nz-list nzItemLayout="vertical">
  @for (quest of computedQuestions(); track quest.id; let i = $index) {
    <nz-list-item [nzNoFlex]="false" [attr.data-index]="quest.id">
      <nz-list-item-meta>
        <nz-list-item-meta-title
          >{{ quest.id }}) &nbsp;{{ quest.titleEN }} <span class="sws-text-required">*</span> {{ quest.files.length }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <nz-list-item-extra>
        <nz-upload
          class="avatar-uploader"
          nzListType="picture-card"
          [nzCustomRequest]="handleUpload"
          [(nzFileList)]="quest.files"
          [nzShowButton]="quest.files.length < quest.maxUploadLimit && formStatus === 'DRAFT'"
          [nzShowUploadList]="{ showPreviewIcon: true, showRemoveIcon: formStatus === 'DRAFT', showDownloadIcon: false }"
          [nzPreview]="handlePreview"
          [nzPreviewFile]="handlePreviewFile"
          [nzPreviewIsImage]="handleIsPreviewImage"
          [nzBeforeUpload]="beforeUpload"
          [nzRemove]="handleRemove"
          [nzName]="quest.id + '_' + i">
          <div>
            <span class="upload-icon" nz-icon nzType="cloud-upload" nzTheme="outline"></span>
            <p class="ant-upload-text">Upload</p>
          </div>
        </nz-upload>
      </nz-list-item-extra>
    </nz-list-item>
  }
</nz-list>
