import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneNumber } from '@util';

@Pipe({
  name: 'phoneNumber',
  standalone: false,
})
export class SwsPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    return value ? formatPhoneNumber(value) : value;
  }
}
