import { Component, inject, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwsDataType } from '@model';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'sws-confirmation',
  templateUrl: './sws-confirmation.component.html',
  styleUrl: './sws-confirmation.component.scss',
  standalone: false,
})
export class SwsConfirmationComponent implements OnInit, OnDestroy {
  readonly nzData: SwsDataType = inject(NZ_MODAL_DATA);
  constructor(
    private readonly modalRef: NzModalRef,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  get title() {
    return this.nzData?.title ? this.sanitizer.sanitize(SecurityContext.HTML, this.nzData.title) : undefined;
  }

  get content() {
    return this.nzData.content ? this.sanitizer.sanitize(SecurityContext.HTML, this.nzData.content) : undefined;
  }

  ngOnDestroy(): void {
    this.modalRef.destroy();
  }
}
