<sws-page-subtitle>
  <ng-template sws-title> Applicant's Supporter Information </ng-template>
  <button
    extra
    nz-tooltip
    sws-button
    swsSize="sm"
    type="button"
    (click)="addNewSupporter()"
    [disabled]="!(supporters.length < 2 && (formStatus$ | async) === 'DRAFT')">
    Add
  </button>
</sws-page-subtitle>

<sws-data-table [data]="supporters" [columns]="columns" swsMode="simple" />

<ng-template let-idx="index" #actionRef>
  <a
    class="sws-btn-danger"
    nz-button
    nzType="link"
    (click)="remove(idx)"
    nz-tooltip
    nzTooltipTitle="Remove"
    x
    [attr.aria-hidden]="true"
    [disabled]="(formStatus$ | async) !== 'DRAFT'">
    <span nz-icon nzType="sws:trash"></span>
  </a>
</ng-template>
