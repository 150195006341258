import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[sws-input]',
  standalone: false,
})
export class SwsInputDirective implements OnInit {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}
  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add('sws-input');
  }
}
