import { Injectable } from '@angular/core';
import { BloodType, EducationStatus, Gender, GenderType, IDivision, MaritalStatus } from '@model';
import { differenceInCalendarDays } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { reduce as _reduce, groupBy as _groupBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly titleSub: BehaviorSubject<string>;
  private loading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.titleSub = new BehaviorSubject('');
  }

  get genderTypes(): GenderType[] {
    return [
      { id: 1, label: Gender.MALE, value: Gender.MALE },
      { id: 2, label: Gender.FEMALE, value: Gender.FEMALE },
    ];
  }

  get sexualities(): GenderType[] {
    return [
      { id: 3, label: Gender.OTHER, value: Gender.OTHER },
      { id: 4, label: Gender.NOTMENTIONED, value: Gender.NOTMENTIONED },
      ...this.genderTypes,
    ];
  }

  get bloodTypes(): BloodType[] {
    return [BloodType.A, BloodType.B, BloodType.AB, BloodType.O];
  }

  get martialStatus(): MaritalStatus[] {
    return [MaritalStatus.SINGLE, MaritalStatus.MARRIED, MaritalStatus.WIDOWED, MaritalStatus.DIVORCED];
  }

  sortById(data: GenderType[]) {
    return data.sort((a, b) => a.id! - b.id!);
  }

  get educationStatus(): EducationStatus[] {
    return [EducationStatus.PASSED, EducationStatus.FAILED];
  }

  // Can not select days before today and today
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;

  loadAllCities(): Observable<IDivision[]> {
    return this.http.get<IDivision[]>(`/setting/cities`);
  }

  setTitle(value: string) {
    this.titleSub.next(value);
  }

  getTitle(): BehaviorSubject<string> {
    return this.titleSub;
  }

  setLoading(loading: boolean) {
    this.loading.next(loading);
  }

  getLoading() {
    return this.loading;
  }
}
