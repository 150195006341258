import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducer,
  ActionReducerMap,
  createAction,
  createFeatureSelector,
  createReducer,
  createSelector,
  MetaReducer,
  on,
  props,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromApplication from './application';
import * as fromAuth from './auth';
import * as fromCity from './city';
import * as fromDepartment from './department';
import * as fromDocumentQuest from './docs';
import * as fromRole from './role';
import * as fromUser from './user';

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from '@model';
import { decrypt, encrypt } from '@util';
import { routerStateKey } from './router';

export const APP_STATE_KEY = 'app';

export interface IApplication {
  lang: Language | null;
}

export const initialAppState: IApplication = {
  lang: null,
};

export const changeLanguage = createAction('[application] Change Language', props<{ lang: Language }>());

export const appReducer = createReducer(
  initialAppState,
  on(changeLanguage, (state, { lang }) => {
    return { ...state, lang };
  })
);

const languageSelector = createFeatureSelector<IApplication>(APP_STATE_KEY);
export const selectCurrentLang = createSelector(languageSelector, state => state.lang);

export interface IAppState {
  [fromAuth.AUTH_STATE_KEY]: fromAuth.IAuthState;
  [fromRole.ROLE_STATE_KEY]: fromRole.IRoleState;
  [fromUser.USER_STATE_KEY]: fromUser.UserState;
  [fromCity.CITY_STATE_KEY]: fromCity.ICityState;
  [fromDepartment.DEPARTMENT_STATE_KEY]: fromDepartment.IDepartmentState;
  [fromDocumentQuest.DOCUMENT_QUEST_STATE_KEY]: fromDocumentQuest.IDocQuestionState;
  [fromApplication.APPLICATION_FORMS_STATE_KEY]: fromApplication.IApplicationFormState;
  [routerStateKey]: RouterReducerState;
  [APP_STATE_KEY]: IApplication;
}

export const reducers: ActionReducerMap<IAppState> = {
  auth: fromAuth.authReducer,
  role: fromRole.roleReducer,
  user: fromUser.userReducer,
  city: fromCity.cityReducer,
  application: fromApplication.applicationFormReducer,
  department: fromDepartment.departmentReducer,
  docs_quest: fromDocumentQuest.docsQuestReducer,
  router: routerReducer,
  app: appReducer,
};

function localStorageSyncReducer(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
  return localStorageSync({
    keys: [
      APP_STATE_KEY,
      {
        [fromRole.ROLE_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
      {
        [fromAuth.AUTH_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
      {
        [fromUser.USER_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
      {
        [fromCity.CITY_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
      {
        [fromDepartment.DEPARTMENT_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
      {
        [fromDocumentQuest.DOCUMENT_QUEST_STATE_KEY]: {
          encrypt: (state: string) => encrypt(state),
          decrypt: (state: string) => decrypt(state),
        },
      },
    ],
    storage: localStorage,
    rehydrate: true,
    restoreDates: true,
    removeOnUndefined: true,
    storageKeySerializer: (key: string) => (key == APP_STATE_KEY ? APP_STATE_KEY : `__${btoa(key)}__`),
  })(reducer);
}

// export const metaReducers: MetaReducer<IAppState, Action>[] = !isDevMode()
//   ? [localStorageSyncReducer, logoutMetaReducer]
//   : [localStorageSyncReducer, logoutMetaReducer];

export function metaReducerFactory(): MetaReducer<IAppState> {
  const router = inject(Router);
  return (reducer: ActionReducer<IAppState>) => (state, action) => {
    if (action.type === fromAuth.logoutSuccess.type || action.type === fromAuth.clearAuthState.type) {
      router.navigateByUrl('/login', { replaceUrl: true, onSameUrlNavigation: 'reload' });

      return reducer({ [APP_STATE_KEY]: (state as IAppState)[APP_STATE_KEY] } as IAppState, action);
    }
    return localStorageSyncReducer(reducer)(state, action);
  };
}

export * from './app.effect';
export * from './application';
export * from './auth';
export * from './city';
export * from './department';
export * from './docs';
export * from './role';
export * from './router';
export * from './user';
