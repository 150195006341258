import { Attribute, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormGroupDirective } from '@angular/forms';
import { ExperienceFormComponent } from '@components';
import { experienceTableColumns, IExperienceInfo, SwsColumnType } from '@model';
import { Store } from '@ngrx/store';
import { getSelectedApplicationFormStatus, IAppState } from '@store';
import { SWS_MODAL_FORM } from '@util';

@Component({
  selector: 'sws-experience-info',
  templateUrl: './experience-info.component.html',
  styleUrls: ['./experience-info.component.scss'],
  standalone: false,
})
export class ExperienceInfoComponent implements OnInit {
  formStatus$ = inject(Store<IAppState>).select(getSelectedApplicationFormStatus);
  form!: FormArray;
  @ViewChild('actionRef', { static: true })
  actionRef!: TemplateRef<IExperienceInfo>;
  @ViewChild('durationRef', { static: true })
  durationRef!: TemplateRef<void>;
  @ViewChild('durationValueRef', { static: true })
  durationValueRef!: TemplateRef<IExperienceInfo>;
  columns!: Array<SwsColumnType<IExperienceInfo>>;

  useModalForm = inject(SWS_MODAL_FORM);
  constructor(
    @Attribute('formArrayName') readonly formArrayName: string,
    private readonly rootForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.form = this.rootForm.control.get(this.formArrayName) as FormArray;
    this.columns = experienceTableColumns(this.actionRef, this.durationRef, this.durationValueRef);
  }

  get experienceInfoList(): IExperienceInfo[] {
    return this.form?.value || [];
  }

  addNewExperience = () => this.useModalForm('Experience Information', ExperienceFormComponent, this.form);

  remove(index: number) {
    this.form.removeAt(index);
  }
}
