<ng-template #list>
  @if (locale && !nzFileListRender) {
    <nz-upload-list
      #listComp
      [style.display]="nzShowUploadList ? '' : 'none'"
      [locale]="locale"
      [listType]="nzListType"
      [items]="nzFileList || []"
      [icons]="$any(nzShowUploadList)"
      [iconRender]="nzIconRender"
      [previewFile]="nzPreviewFile"
      [previewIsImage]="nzPreviewIsImage"
      [onPreview]="nzPreview"
      [onRemove]="onRemove"
      [onDownload]="nzDownload"
      [dir]="dir"
    ></nz-upload-list>
  }
  @if (nzFileListRender) {
    <ng-container *ngTemplateOutlet="nzFileListRender; context: { $implicit: nzFileList }"></ng-container>
  }
</ng-template>
<ng-template #con><ng-content></ng-content></ng-template>
<ng-template #btn>
  <div [class]="classList" [style.display]="nzShowButton ? '' : 'none'">
    <div nz-upload-btn #uploadComp [options]="_btnOptions!">
      <ng-template [ngTemplateOutlet]="con"></ng-template>
    </div>
  </div>
</ng-template>
@if (nzType === 'drag') {
  <div [class]="classList" (drop)="fileDrop($event)" (dragover)="fileDrop($event)" (dragleave)="fileDrop($event)">
    <div nz-upload-btn #uploadComp [options]="_btnOptions!" class="ant-upload-btn">
      <div class="ant-upload-drag-container">
        <ng-template [ngTemplateOutlet]="con"></ng-template>
      </div>
    </div>
  </div>
  <ng-template [ngTemplateOutlet]="list"></ng-template>
} @else {
  @if (nzListType === 'picture-card') {
    <ng-template [ngTemplateOutlet]="list"></ng-template>
    <ng-template [ngTemplateOutlet]="btn"></ng-template>
  } @else {
    <ng-template [ngTemplateOutlet]="btn"></ng-template>
    <ng-template [ngTemplateOutlet]="list"></ng-template>
  }
}
