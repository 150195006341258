import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IApplicationForm, IPagedApplicationForm, Pageable } from '@model';

// load
export const loadAllApplications = createAction('[application] Load All Applications', props<Pageable>());
export const loadAllApplicationsSuccess = createAction(
  '[application] Load All Applications Success',
  props<{ data: IPagedApplicationForm[]; total: number }>()
);
export const loadAllApplicationsFailed = createAction('[application] Load All Applications Failed');

// By ID
export const loadApplicationByFormID = createAction('[application | formID] Load Application By', props<{ id: string }>());
export const loadApplicationByFormIDSuccess = createAction('[application | formID] Load Application By Success', props<IApplicationForm>());
export const loadApplicationByFormIDFailed = createAction('[application | formID] Load Application By Failed');

// create
export const addApplication = createAction('[application] Add New Application', props<{ data: IApplicationForm }>());
export const addApplicationSuccess = createAction('[application] Add New Application Success', props<{ data: IApplicationForm }>());
export const addApplicationFailed = createAction('[application] Add New Application Failed');

// remove
export const removeApplication = createAction('[application] Remove Application', props<{ id: number }>());
export const removeApplicationSuccess = createAction('[application] Remove Application Success', props<{ id: number }>());
export const removeApplicationFailed = createAction('[application] Remove Application Failed');

export const removeSelectedApplication = createAction('[application] Remove Selected Application');

// update
export const updateApplication = createAction('[application] Update Application', props<{ id: number; data: IApplicationForm }>());
export const updateApplicationSuccess = createAction('[application] Update Application Success', props<Update<IApplicationForm>>());
export const updateApplicationFailed = createAction('[application] Update Application Failed');
