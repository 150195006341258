import { IRole, IUser } from '@model';
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.action';

export const AUTH_STATE_KEY = 'auth';

export interface IAuthState extends Partial<IUser> {
  token: string | null;
  isLoading: boolean;
  currentRole: IRole | null;
}

export const initialAuthState: IAuthState = {
  token: null,
  isLoading: false,
  currentRole: null,
};

export function selectAuthId(auth: IAuthState): number {
  //In this case this would be optional since primary key is id
  return auth.id!;
}

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.login, (state, _) => {
    return { ...state, isLoading: true };
  }),
  on(AuthActions.loginSuccess, (state, { token, user }) => {
    return { ...state, isLoading: false, token, roles: [...user.roles!], currentRole: user.roles![0], ...user };
  }),
  on(AuthActions.loginFailed, (state, _action) => ({
    ...state,
    isLoading: false,
    token: null,
  })),
  on(AuthActions.getPrincipalSuccess, (state, { type, ...user }) => (user ? { ...state, roles: [...user.roles!], ...user } : { ...state }))
);
