import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserService } from '@services';
import { IAppState, selectUserPageable } from '@store';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';

import * as UserActions from './user.action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router,
    private store: Store<IAppState>
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAllUsers),
      mergeMap(({ pageIndex, pageSize, sort, filters }) =>
        this.userService.loadUsers({ pageIndex, pageSize, sort, filters }).pipe(
          map(({ data, total }) => UserActions.loadAllUsersSuccess({ users: [...data], total })),
          catchError(() => of(UserActions.loadAllUsersFailed()))
        )
      )
    )
  );

  onAddUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.addUser),
      mergeMap(({ roles, type, ...data }) => {
        return this.userService.create({ ...data, roles }).pipe(
          map(createdUser => {
            this.router.navigateByUrl('/user');
            return UserActions.addUserSuccess({ ...createdUser });
          }),
          catchError((err: HttpErrorResponse) => {
            console.log('Error => ', err);
            return of(UserActions.addUserFailed({ message: err.error.errors[0].message }));
          })
        );
      })
    )
  );

  onRemoveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removeUser),
      withLatestFrom(this.store.select(selectUserPageable)),
      mergeMap(([{ id }, pageData]) =>
        this.userService.deleteOne(id).pipe(
          switchMap(() => [UserActions.removeUserSuccess({ id }), UserActions.loadAllUsers({ ...pageData })]),
          catchError(() => of(UserActions.removeUserFailed()))
        )
      )
    )
  );

  onUpdateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap(({ id, user }) =>
        this.userService.updateOne(id, { ...user }).pipe(
          map(updatedUser => {
            this.router.navigateByUrl('/user');
            return UserActions.updateUserSuccess({
              user: {
                changes: {
                  ...updatedUser,
                },
                id: id!,
              },
            });
          }),
          catchError(() => of(UserActions.updateUserFailed()))
        )
      )
    )
  );
}
