import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { DepartmentService } from '@services';
import * as DepartmentActions from './department.action';
import { Store } from '@ngrx/store';
import { IAppState, selectDepartmentPageable } from '@store';

@Injectable()
export class DepartmentEffects {
  constructor(
    private actions$: Actions,
    private departmentService: DepartmentService,
    private router: Router,
    private store: Store<IAppState>
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.loadAllDepartments),
      mergeMap(({ pageIndex, pageSize, filters, sort }) => {
        return this.departmentService.loadDepartments({ pageIndex, pageSize, filters, sort }).pipe(
          map(({ data, total }) => DepartmentActions.loadAllDepartmentsSuccess({ data: [...data], total })),
          catchError(() => of(DepartmentActions.loadAllDepartmentsFailed()))
        );
      })
    )
  );

  onAddDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.addDepartment),
      mergeMap(({ data }) =>
        this.departmentService.create(data).pipe(
          map(createdDepartment => {
            this.router.navigateByUrl('/department');
            return DepartmentActions.addDepartmentSuccess({ data: createdDepartment });
          }),
          catchError(() => of(DepartmentActions.addDepartmentFailed()))
        )
      )
    )
  );

  onRemoveDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.removeDepartment),
      withLatestFrom(this.store.select(selectDepartmentPageable)),
      mergeMap(([{ id }, pageable]) =>
        this.departmentService.deleteOne(id).pipe(
          switchMap(() => [DepartmentActions.removeDepartmentSuccess({ id }), DepartmentActions.loadAllDepartments({ ...pageable })]),
          catchError(() => of(DepartmentActions.removeDepartmentFailed()))
        )
      )
    )
  );

  onUpdateDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.updateDepartment),
      mergeMap(({ id, department }) =>
        this.departmentService.updateOne(id, department).pipe(
          map(data => {
            this.router.navigateByUrl('/department');
            return DepartmentActions.updateDepartmentSuccess({
              changes: {
                ...data,
              },
              id: id!,
            });
          }),
          catchError(() => of(DepartmentActions.updateDepartmentFailed()))
        )
      )
    )
  );
}
