<nz-collapse [nzExpandIconPosition]="'end'">
  <nz-collapse-panel #collapsedPanel [nzHeader]="headerTpl" [nzActive]="true" [nzDisabled]="false" [nzExpandedIcon]="expandIconTpl">
    <ng-content></ng-content>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #expandIconTpl>
  <span nz-icon nzType="caret-down" [nzRotate]="collapsedPanel.nzActive ? 0 : 270" class="sws-collapse-icon"></span>
</ng-template>

<ng-template #headerTpl>
  <nz-page-header-subtitle>
    {{ title() }}
  </nz-page-header-subtitle>
</ng-template>
